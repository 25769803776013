import React, { useState, useEffect } from 'react';
import alertNotify from '../../components/alert-notify';
import BounceSpinner from '../../components/bounce-spinner';
import { getDashboardData } from '../../services/dashboard';
import formatters from '../../utils/formatters';
import { Grid, Box, BoxGrafico, BoxTitle, Group, Text, Value, Line, FlexEnd, GraphValue } from './style';
import DashboardGraph from '../../components/dashboard-chart';

const DashboardPage = ({ navigation }) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getData() {
            setIsLoading(true);

            await getDashboardData()
            .then(response => {
                setData({
                    ...response.data,
                    campaignsData: {
                        ...response.data.campaignsData,
                        byDay: response.data.campaignsData.byDay.map(day => {
                            return {
                                ...day,
                                value: parseFloat(day.value)
                            }
                        })
                    }
                });
            }).catch(err => {
                alertNotify('Ocorreu um erro ao recuperar os dados.', null, 'danger');
            })
            .finally(() => setIsLoading(false));
        }

        getData();
    }, []);

    return (
        <>
            {isLoading ? (
                <BounceSpinner />
            ) : (
                <Grid>
                    <Box>
                        <BoxTitle>Total De Campanhas</BoxTitle>
                        <Group>
                            <Text>Quantidade</Text>
                            <Value>
                                {data?.campaignsData?.totalCampanhasGeral || 0}
                            </Value>
                        </Group>
                    </Box>
                    <Box>
                        <BoxTitle>Marcas Full</BoxTitle>
                        <Group>
                            <Text>Quantidade</Text>
                            <Value>{data?.planCount?.PRO || 0}</Value>
                        </Group>
                        <Group>
                            <Text>Total Em Campanhas</Text>
                            <Value>
                                {formatters.moneyBR(data?.campaignsData?.totalFaturamentoGeral || 0)}
                            </Value>
                        </Group>
                    </Box>
                    <Box>
                        <BoxTitle>Brand Fans</BoxTitle>
                        <Group>
                            <Text>Quantidade</Text>
                            <Value>{data?.brandFans || 0}</Value>
                        </Group>
                    </Box>
                    <BoxGrafico>
                        <Line>
                            <BoxTitle>Campanhas</BoxTitle>
                            <FlexEnd>
                                <GraphValue>
                                    {formatters.moneyBR(data?.campaignsData?.totalFaturamentoMes || 0)}
                                </GraphValue>
                                <Text>Últimos 30 Dias</Text>
                            </FlexEnd>
                        </Line>

                        {data?.campaignsData?.byDay && (
                            <DashboardGraph 
                                data={data?.campaignsData?.byDay}
                            />
                        )}

                    </BoxGrafico>
                </Grid>
            )}
        </>
    )
};

export default DashboardPage;