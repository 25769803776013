import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--black-color);
`;

export const Form = styled.form`
    gap: 20px;
    display: flex;
    flex-direction: column;
`;

export const Logo = styled.img`
    width: 180px;
    object-fit: cover;
    margin-bottom: 25px;

    @media(max-width: 768px) {
        margin-bottom: 10px; 
    }
`;

export const Title = styled.h1`
    font-weight: 500;
    font-size: 30px;
    font-family: Quicksand;
    letter-spacing: -0.9px;
    margin: 50px 0;
    color: #fff;

    @media(max-width: 768px) {
        margin: initial 0; 
    }
`;

export const Icon = styled.button`
    border: none;
    outline: none;
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 22px;
    height: 22px;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: rgba(83, 26, 197, .7);
    }
`;