import React from 'react';
import styled from 'styled-components';

const NotFoundItems = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    )
};

export default NotFoundItems;

const Container = styled.span`
    text-align: center;
    margin: 50px auto 0;
    opacity: .7;
`;