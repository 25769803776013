import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
`;

export const ButtonDelete = styled.button`
    font-size: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    
    i {
        color: #fff;
    }

    :hover {
        opacity: .8;
    }
`;

export const Button = styled(Link)`
    background-color: var(--primary-color);
    border-radius: 9px;
    font-size: 16px;
    font-family: Cerebri Sans;
    letter-spacing: -0.48px;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 16px;
    width: fit-content;
    align-self: center;
    text-decoration: none;

    :disabled {
        opacity: 0.2;
    }

    :hover {
        opacity: .8;
        color: #fff;
    }

    @media(max-width: 768px) {
        margin-top: 20px; 
    }
`;

export const Line = styled.div`
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
    align-items: center;

    & + & {
        margin-top: 20px;
    }

    @media(max-width: 920px) {
        gap: 30px;
    }
`;

export const TextVerified = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .uploadcare--widget__button_type_open, .uploaded-file {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        white-space: break-spaces;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
`;

export const Relative = styled.div`
    position: relative;
`;

export const ProfilePicture = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #fff;
`;

export const ButtonRemoveImg = styled.button`
    outline: none;
    border: 2px solid var(--secondary-color);
    background-color: var(--primary-color);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
`;

export const Group = styled(Line)`
    gap: 20px;
    flex-wrap: nowrap;

    .input {
        width: 280px;
        flex: 1;
    }

    & + & {
        margin-top: 20px;
    }
`;

export const LineNoWrap = styled(Line)`
    align-items: flex-start;
`;

export const ColumnFlex = styled(Column)`
    flex: 1;

    h2 {
        margin: 20px 0 20px;
    }
`;

export const ButtonConfirm = styled.button`
    background-color: var(--primary-color);
    border-radius: 9px;
    font-size: 16px;
    font-family: Cerebri Sans;
    letter-spacing: -0.48px;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 16px;
    width: fit-content;
    align-self: center;
    text-decoration: none;
    border: none;
    outline: none;

    .spinner-border {
        margin-right: 8px;
    }

    :hover {
        opacity: .8;
        color: #fff;
    }

    :disabled {
        opacity: 0.2;
    }

    @media(max-width: 768px) {
        margin-top: 20px; 
    }
`;

export const ButtonCancel = styled(ButtonConfirm)`
    border: 1px solid #707070;
    background-color: #fff;
    color: #707070;

    :hover {
        background-color: #707070;
        color: #fff;
    }

    :disabled {
        background-color: #fff;
        color: #707070;
    }
`;

export const FlexEnd = styled(Line)`
    justify-content: flex-end;
    margin-top: 50px;
`;

export const BannerContainer = styled(Column)`
    width: 100%;
    
    .uploadcare--widget__button_type_open, .uploaded-file {
        background-color: var(--black-color);
        border-radius: 6px;
        display: flex;
        padding: 6px 15px;
        height: 45px;
        gap: 5px;
        position: relative;
        align-items: center;
        font-size: 16px;
        color: #fff;
        font-family: 'Cerebri Sans';
        width: 100%;
    }
`;

export const Banner = styled.div`
    background-color: var(--black-color);
    border-radius: 6px;
    display: flex;
    padding: 6px 15px;
    height: 45px;
    gap: 5px;
    position: relative;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-family: 'Cerebri Sans';
    
    span {
        flex: 1;
    }
`;

export const ContainerIcon = styled.div`
    display: flex;
    align-items: center;
`;

export const TransparentButton = styled.button`
    border: none;
    outline: none;
    padding: 5px 2px;
    background-color: transparent;
    display: flex;
    color: #fff;
    font-size: 18px;
`;

export const BannerImg = styled.img`
    width: 100%;
    max-height: 500px;
    object-fit: cover;
`;