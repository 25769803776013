import React from 'react';
import { StyledSelect, Option } from './style';

const Select = ({ 
    id,
    options, 
    onChange,
    placeholder,
    selectedOption
}) => {
    return (
        <StyledSelect 
            id={id}
            placeholder={placeholder}
            value={selectedOption || ""} 
            onChange={(e) => onChange(e)}
        >
            <Option value='' selected disabled>{placeholder}</Option>
            
            {options && options.length && options.map(option => {
                return (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                )
            })}
        </StyledSelect>
    )
};

export default Select;