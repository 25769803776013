import $ from "jquery";
import "bootstrap-notify";

const alertNotify = function (text, center = false, type = "info", url = null) {
  const settings = center
    ? {
        type: type,
        icon_type: "class",
        z_index: 2147483648,
        placement: {
          from: "top",
          align: "center",
        },
      }
    : {
        type: type,
        icon_type: "class",
        z_index: 2147483648,
      };

  $.notify(
    {
      // options
      icon: "fa fa-warning",
      message: text,
      url: url,
    },
    {
      // settings
      ...settings,
    }
  );
};

export default alertNotify;