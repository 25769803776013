import React from 'react';
import styled from 'styled-components';

const PageTitle = ({ children }) => {
    return (
        <Title>
            {children}
        </Title>
    )
};

export default PageTitle;

const Title = styled.h1`
    font-weight: 400;
    font-size: 25px;
    font-family: Quicksand;
    letter-spacing: 0px;
    color: #FFFFFF;
`;