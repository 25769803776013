import LoginPage from '../pages/login';
import TwoAuthPage from '../pages/two-auth';
import DashboardPage from '../pages/dashboard';
import BrandsPage from '../pages/brands';
import BrandPage from '../pages/brand';
import InfluencersPage from '../pages/influencers';
import InfluencerPage from '../pages/influencer';
import ProfilePage from '../pages/profile';
import RecoverPasswordPage from '../pages/recover-password';
import RedefinePasswordPage from '../pages/redefine-password';
import CampaignsPage from '../pages/campaigns';

const routes = [
  {
    path: "/",
    name: "Login",
    element: LoginPage,
    exact: true,
  },
  {
    path: "/recover-password",
    name: "Recuperação de senha",
    element: RecoverPasswordPage,
    exact: true,
  },
  {
    path: "/redefine-password/:token",
    name: "Redefinição de senha",
    element: RedefinePasswordPage,
    exact: true,
  },
  {
    path: "/login",
    name: "Login",
    element: LoginPage,
    exact: true,
  },
  {
    path: "/two-auth",
    name: "Dupla autenticação",
    element: TwoAuthPage,
    exact: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    element: DashboardPage,
    exact: true,
    private: true,
  },
  {
    path: "/brands",
    name: "Marcas",
    element: BrandsPage,
    exact: true,
    private: true,
  },
  {
    path: "/brand/:id",
    name: "Marca",
    element: BrandPage,
    exact: true,
    private: true,
  },
  {
    path: "/brand/:id/campaigns",
    name: "Campanhas da marca",
    element: CampaignsPage,
    exact: true,
    private: true,
  },
  {
    path: "/influencers",
    name: "Influencers",
    element: InfluencersPage,
    exact: true,
    private: true,
  },
  {
    path: "/influencer/:id",
    name: "Influencer",
    element: InfluencerPage,
    exact: true,
    private: true,
  },
  {
    path: "/profile",
    name: "Minha Conta",
    element: ProfilePage,
    exact: true,
    private: true,
  },
  /* {
    path: "*",
    name: "Not found",
    element: NotFound,
    exact: true,
  }, */
];
  
export default routes;
