import styled from "styled-components";

const Button = styled.button`
  border: 1px dashed white;
  margin: 0;
  color: white;
  outline: none;
  background-color: transparent;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 0.25rem;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    border: ${props => props.isToAddBorderAnimate ? "none" : "auto"};
  }

  &.add-border-animate {
    background: linear-gradient(90deg, #FDFDFD 50%, transparent 50%), 
                linear-gradient(90deg, #FDFDFD 50%, transparent 50%), 
                linear-gradient(0deg, #FDFDFD 50%, transparent 50%), 
                linear-gradient(0deg, #FDFDFD 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 4px 1.5px, 4px 1.5px, 1.5px 4px, 1.5px 4px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    animation: dash 5s linear infinite;
    margin: 0;
    padding: 1px;
  }

  @keyframes dash {
    to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
  }
`;

const BackgroundBox = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, .5);

  @media (max-width: 720px) {
    display: block;
  }
`;

const ContentBox = styled.article`
  width: 60%;
  max-width: 1050px;
  display: flex;
  flex-direction: column;
  background-color: #FDFDFD;
  padding: 1.25rem;

  button {
    outline: none;
    border: none;
    cursor: pointer;
  }

  @media (min-width: 720px) {
    border-radius: 0.5rem;
  }

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const Header = styled.header`
  button {
    float: right;
    font-weight: 700;
    font-size: 1.5rem;
    color: #ff3d6f;
    background-color: transparent;

    &:hover {
      animation: opacity 0.5s forwards;
    }

    &:not(:hover) {
      animation: reverse-opacity 0.5s forwards;
    }
}

@keyframes opacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes reverse-opacity {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
`;

const Content = styled.form`
  display: ${(props) => (props.isSomeFileUploaded ? "block" : "flex")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  min-height: 600px;
  position: relative;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  h2 {
    font-size: 2.5rem !important;
    line-height: 50px;
    color: #353535;
  }

  h3 {
    font-size: 2rem !important;
    line-height: 50px;
    color: #353535;
  }

  p {
    font-size: 0.8rem;
    font-weight: 400;
    color: #353535;
  }

  button {
    position: relative;
    border: none;
    font-size: 18px;
    transition: color 0.5s, transform 0.2s, background-color 0.2s;
    outline: none;
    border-radius: 3px;
    margin: 0 10px;
    padding: 23px 33px;
    border: 3px solid transparent;

    span {
      position: relative;
      z-index: 1;
    }

    &:active {
      transform: translateY(3px);
    }

    &::after,
    &::before {
      border-radius: 3px;
    }
  }

  p,
  h2 {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    min-height: 120px;
    padding: 1rem;
    margin: 0;
    cursor: grab;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    padding: 0;
    margin: 0;

    figure {
      img {
        max-width: 100px;
        height: 80px;
        width: 100px;
        object-fit: cover;
      }

      figcaption {
        text-align: center;
        color: black;
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .add-border-animate {
    background: linear-gradient(90deg, #157cfc 50%, transparent 50%),
      linear-gradient(90deg, #157cfc 50%, transparent 50%),
      linear-gradient(0deg, #157cfc 50%, transparent 50%),
      linear-gradient(0deg, #157cfc 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 16px 4px, 16px 4px, 4px 16px, 4px 16px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    animation: dash 5s linear infinite;
  }

  .material-bubble {
    background-color: transparent;
    color: rgb(45, 45, 54);
    border: none;
    overflow: hidden;
    box-shadow: none;

    &:hover {
      color: #fdfdfd;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 3px solid #ff3d6f;
      transition: opacity 0.3s, border 0.3s;
    }

    &:hover::before {
      opacity: 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 400px;
      height: 210px;
      background-color: #ff3d6f;
      border-color: transparent;
      border-radius: 50%;
      transform: translate(-10px, -70px) scale(0.1);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
    }

    &:hover::after {
      opacity: 1;
      transform-origin: 100px 100px;
      transform: scale(1) translate(-10px, -70px);
    }
  }

  .cancel-button {
    &::before {
      border: 3px solid #e32636;
    }

    &::after {
      background-color: #e32636;
    }
  }

  .buttons-form {
    display: flex;
    align-items: center;
    font-size: 1rem;

    button {
      padding: 0.75rem 3rem;
    }
  }

  @media (min-width: 720px) {
    p,
    h2 {
      display: block;
    }
  }

  @media (max-width: 720px) {
    min-height: 100dvh;

    label {
      display: none;
    }
  }

  @keyframes dash {
    to {
      background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
  }
`;

export { Button, BackgroundBox, Content, ContentBox, Header };
