import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

const BarraStatus = ({ activeStatus, handleActiveStatus }) => {
    const allStatus = useMemo(() => {
        return [
            {id: 'ALL', label: 'Todas'},
            {id: 'DRAFT', label: 'Rascunhos'},
            {id: 'REVIEW', label: 'Em Revisão'},
            {id: 'ACTIVE', label: 'Ativas'},
            {id: 'PAUSED', label: 'Pausadas'},
            {id: 'FINISHED', label: 'Finalizadas'},
        ]
    }, []);

    return (
        <Container>
            {allStatus.map(status => {
                return (
                    <Tab 
                        active={activeStatus === status.id} 
                        onClick={() => handleActiveStatus(status.id)}
                    >
                        {status.label}
                    </Tab>
                )
            })}
        </Container>
    )
};

export default BarraStatus;

const Container = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    @media(max-width: 768px) {
        justify-content: center;
    }
`;

const Tab = styled.div`
    border-radius: 7px;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 12px;
    font-family: Cerebri Sans;
    text-transform: uppercase;
    padding: 5px 10px;
    cursor: pointer;
    opacity: .5;
    border: 1px solid #FFFFFF;
    background-color: transparent;
    height: fit-content;
    text-align: center;
    white-space: nowrap;

    ${({ active }) => active && activeStyle};
`;

const activeStyle = css`
    opacity: 1;
    border-color: #531AC5;
    background-color: #531AC5;
`;