import styled from 'styled-components';

export const Button = styled.button`
    border: none;
    outline: none;
    background-color: #fff;
    border-radius: 17px;
    font-size: 16px;
    font-family: Cerebri Sans;
    letter-spacing: -0.48px;
    color: var(--secondary-color);
    text-transform: uppercase;
    padding: 8px 16px;
    width: fit-content;
    align-self: center;
    margin-top: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    :disabled {
        opacity: 0.2;
        pointer-events: none;
    }

    .spinner-border {
        margin-right: 8px;
    }

    @media(max-width: 768px) {
        margin-top: 20px; 
    }

    ${({ isLoading }) => isLoading && 'pointer-events: none;'};
`;