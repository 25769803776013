import axios from "axios";
import { getItem, setItem } from "./localStorage";
import * as constants from "../constants/api";

const instance = axios.create({
  baseURL: constants.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = getItem(constants.TOKEN_KEY);
    const refresh_token = getItem(constants.REFRESH_TOKEN_KEY);
    if (!token) return config;
    
    const bearer = `Bearer ${
      config.url.includes('/auth/refresh-token') ? refresh_token : token
    }`;

    config.headers["Authorization"] = bearer;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error?.response?.status !== 403) return Promise.reject(error);

    const originalRequest = error.config;

    if (
      error.response.status === 403 &&
      originalRequest.url === '/auth/refresh-token'
    ) {
      window.location.pathname = '/logout';
      return Promise.reject(error);
    }

    if (!error?.response?.status === 403 || originalRequest._retry)
      return Promise.reject(error);

    originalRequest._retry = true;

    return instance.get('/auth/refresh-token')
    .then(({ status, data }) => {
      if (status === 200) {
        const { token, refresh_token } = data;
        setItem(constants.TOKEN_KEY, token);
        setItem(constants.REFRESH_TOKEN_KEY, refresh_token);
        setTokenOnHeader(token);
        return instance(originalRequest);
      }
    })
    .catch(error => {
      console.log('Error on refresh token', error);
      return Promise.reject(error);
    });
  }
);

const setTokenOnHeader = (token) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export default instance;
export { setTokenOnHeader };
