import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Button } from './style';

const ButtonLogin = ({ type, disabled, isLoading, children, ...rest }) => {
    return (
        <Button disabled={disabled} type={type} isLoading={isLoading} {...rest}>
            {isLoading 
                ? (
                    <>
                        <Spinner as="span" animation="border" role="status" size="sm" />
                        Carregando...
                    </>
                ) 
                : children
            }
        </Button>
    )
};

export default ButtonLogin;