import api from './index';

export const updatePassword = (data) => {
    return api.put('/users/updatePassword', data)
}

export const updateUserInformation = (data) => {
    return api.put('/users/edit/updateInformation', data)
}

export const saveNewProfilePic = (data) => {
    return api.put('/users/edit/newProfileImage', data)
}