import styled from "styled-components";

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  z-index: 100000;
  color: black;
  list-style: none;

  button {
    position: relative;
    top: 15px;
    right: -15px;
    border-radius: 50%;
    padding: 0 0.3rem;
    background-color: #1c1c1c;
    color: white;
    z-index: 1;

    &:hover {
      background-color: #FF3D6F;
      transition: 0.5s;
    }
  }
`;