import React from 'react';
import styled from 'styled-components';

const Label = ({ htmlFor, children }) => {
    return (
        <Container htmlFor={htmlFor}>
            {children}
        </Container>
    )
};

export default Label;

const Container = styled.label`
    font-size: 16px;
    color: #fff;
    font-family: 'Cerebri Sans';
`;