import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '../../constants/api';
import Input from "../../components/input";
import Button from "../../components/button-login";
import logoKuak from "../../assets/images/kuak_logo_branco.svg";
import { setTokenOnHeader } from '../../services';
import { setItem } from '../../services/localStorage';
import { updateNewPassword, getUserProperties, login } from '../../services/auth';
import { Container, Form, Title, Logo } from './style';

const RedefinePassPage = ({ navigation }) => {
    const [credentials, setCredentials] = useState({ password: null, confirmPassword: null });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();

    const handleChange = useCallback((value, field) => {
        setError(false);
        setCredentials(prev => {
            return {
                ...prev,
                [field]: value,
            }
        });
    }, []);

    const submit = useCallback((e, credentials) => {
        e.preventDefault();
        setIsLoading(true);

        const { password, confirmPassword } = credentials;

        if(password !== confirmPassword) {
            setError("As senhas não coincidem.")
            setIsLoading(false);
            return;
        }

        const data = {
            password: password
        };
            
        updateNewPassword(params?.token, data)
        .then(({ data }) => {
            if (data.success) {
                const dataLogin = {
                    email: data.username,
                    password: password
                }

                login(dataLogin)
                .then(async ({ data: responseLogin }) => {
                    if (responseLogin.token && responseLogin.role.some(role => role.authority === 'ROLE_ADMIN')) {
                        setItem("userProperties", null);
                        setItem(TOKEN_KEY, responseLogin.token);
                        setItem(REFRESH_TOKEN_KEY, responseLogin.refresh_token);
                        setTokenOnHeader(responseLogin.token);
                        
                        await getUserProperties();

                        navigate('/dashboard');
                    }
                    else {
                        setError("Você não tem permissão para prosseguir.");
                    }
                })
                .catch(error => {
                    setError("Ocorreu um erro ao realizar seu login. Tente novamente pela página de login.");
                })
            } else {
                setError("Verifique se o link que você acessou está correto.");
            }
        })
        .catch(error => {
            setError("Ocorreu um erro ao atualizar sua senha.");
        })
        .finally(() => setIsLoading(false));
    }, [params]);
    
    return (
        <Container>
            <Logo src={logoKuak} alt="Logo Kuak" />

            <Title>
                Redefinição de Senha
            </Title>

            <Form onSubmit={(e) => submit(e, credentials)}>
                <Input 
                    id="password" 
                    type="password"
                    placeholder="Nova Senha..."
                    value={credentials.password}
                    onChange={value => handleChange(value, 'password')}
                />

                <Input 
                    id="confirm-password" 
                    type="password"
                    placeholder="Confirme a Nova Senha..."
                    value={credentials.confirmPassword}
                    onChange={value => handleChange(value, 'confirmPassword')}
                    errorMessage={error}
                    showErrorMessage={!!error}
                />

                <Button 
                    type="submit" 
                    isLoading={isLoading}
                    disabled={!credentials.password | !credentials.confirmPassword}
                >
                    Salvar nova senha e entrar
                </Button>
            </Form>
        </Container>
    )
};

export default RedefinePassPage;