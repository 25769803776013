import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";
import PrivateRoute from "./routes/PrivateRoute";
import SideBar from "./components/sidebar";
import Logout from "./pages/logout";

const App = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => {
          if (!route.private) {
            return (
              <Route
                key={index}
                path={route.path}
                element={<route.element {...props} {...route.props} />}
              />
            );
          } else {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoute>
                    <SideBar {...props} {...route.props}>
                      <route.element {...props} {...route.props} />
                    </SideBar>
                  </PrivateRoute>
                }
              />
            )
          }
        })}

        <Route
          exact
          path="/logout"
          element={<Logout />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
