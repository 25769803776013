import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--black-color);
`;

export const Form = styled.form`
    gap: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Logo = styled.img`
    width: 180px;
    object-fit: cover;
    margin-bottom: 80px;

    @media(max-width: 768px) {
        margin-bottom: 30px; 
    }
`;

export const LogoGoogle = styled.img`
    width: 100px;
    object-fit: cover;
    margin-bottom: 25px;

    @media(max-width: 768px) {
        margin-bottom: 10px; 
    }
`;

export const PoweredGoogle = styled.img`
    width: 120px;
    object-fit: cover;
    margin-left: auto;
`;

export const BoxInputAuth = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 10px;
    border: ${({ active }) => active ? 'none' : `1px solid #fff`};
    
    @media(max-width: 550px){
        width: 40px;
        height: 40px;
        border-radius: 7px;
    }
`;

export const BoxInputContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 50px;

  @media(max-width: 550px){
    gap: 5px;
  }

    div:nth-child(3) {
        margin-right: 20px;
    }
`;

export const Input = styled.input`
  border: none;
  background-color: ${({ active }) => active ? 'var(--primary-color)' : 'transparent'};
  line-height: initial;
  width:100%;
  height:100%;
  border-radius: 10px;
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  padding: 16px;
  text-align: center;

    @media(max-width: 550px) {
        border-radius: 7px;
        padding:12px;
        font-size: 26px;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
`;

export const LoadingContainer = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .spinner-border {
        color: var(--primary-color) !important;
    }

    ${({ isHidden }) => isHidden && 'visibility: hidden;'}
`;