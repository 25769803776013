import React from 'react';
import styled from 'styled-components';

const TagStatus = ({ color, children }) => {
    return (
        <Tag color={color}>
            {children}
        </Tag>
    )
};

export default TagStatus;

const Tag = styled.span`
    padding: 8px 20px;
    border-radius: 9px;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Cerebri Sans';
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #FFFFFF;
    background-color: ${({ color }) => color || 'var(--primary-color)'};
`;