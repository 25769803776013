import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NUM_ITEMS_PAGE } from '../../constants/config';
import Select from '../../components/select';
import PageTitle from '../../components/page-title';
import TagStatus from '../../components/tag-status';
import SearchInput from '../../components/search-input';
import alertNotify from '../../components/alert-notify';
import BounceSpinner from '../../components/bounce-spinner';
import NotFoundItems from '../../components/not-found-items';
import Pagination from '../../components/pagination';
import { Table, Tbody, Td, Tr } from '../../components/global-style';
import { getAllInfluencers, searchInfluencersByName } from '../../services/influencers';
import userImg from '../../assets/images/person.svg';
import { Box, Line, FlexEnd, LineStart, Image } from './style';

const InfluencersPage = ({ navigation }) => {
    const [activeStatus, setActiveStatus] = useState('todos');
    const [activePage, setActivePage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalInfluencers, setTotalInfluencers] = useState(0);
    const [influencers, setInfluencers] = useState([]);
    const [allInfluencers, setAllInfluencers] = useState([]);
    const [displayedInfluencers, setDisplayedInfluencers] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    const navigate = useNavigate();

    const limit = useMemo(() => NUM_ITEMS_PAGE, []);
    
    const colorsStatus = useMemo(() => {
        return {
            ativo: 'var(--active-status-color)',
            inativo: 'var(--inactive-status-color)',
        }
    }, []);

    const searchInfluencers = useCallback( async name => {
        setIsLoading(true);

        await searchInfluencersByName(name)
        .then(({ data }) => {
            const searchedInfluencers = data.map(influencer => {
                return {
                    id: influencer.id,
                    avatarInfo: influencer.avatarInfo,
                    usernameKard: influencer.usernameKard,
                    name: influencer.name,
                    email: influencer.email,
                    phone: influencer.phone,
                    status: influencer.enabled ? 'ativo' : 'inativo'
                }
            });

            setIsSearching(true);
            setInfluencers(searchedInfluencers);
        })
        .catch(err => {
            alertNotify('Ocorreu um erro ao realizar a pesquisa.', null, 'danger');
        })
        .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        async function getInfluencers(activePage) {
            setIsLoading(true);

            await getAllInfluencers(limit, activePage * limit)
            .then(({ data }) => {
                const { count, response } = data;

                setTotalInfluencers(count);
                setAllInfluencers(response.map(influencer => {
                    return {
                        ...influencer,
                        status: influencer.enabled ? 'ativo' : 'inativo',
                    }
                }));
            })
            .catch(error => {
                alertNotify('Ocorreu um erro ao recuperar a listagem de influencers.', null, 'danger')
            })
            .finally(() => setIsLoading(false));
        }

        getInfluencers(activePage);
    }, [activePage, limit]);

    useEffect(() => setInfluencers(allInfluencers), [allInfluencers]);

    useEffect(() => {
        setDisplayedInfluencers(activeStatus === 'todos' 
            ? influencers 
            : influencers.filter(influencer => influencer.status === activeStatus)
        )
    }, [influencers, activeStatus]);

    return (
        <Box>
            <Line>
                <PageTitle>
                    Influencers
                </PageTitle>
                <FlexEnd>
                    <Select
                        id="select-status"
                        options={[
                            { value: 'todos', label: 'Todas' },
                            { value: 'ativo', label: 'Ativos' },
                            { value: 'inativo', label: 'Inativos' },
                        ]} 
                        onChange={e => setActiveStatus(e.target.value)}
                        placeholder="Status"
                        selectedOption={activeStatus}
                    />
                    <SearchInput
                        id="search-influencer"
                        placeholder="Procurar por influencer..."
                        onChange={event => {
                            if(event.target.value.length === 0) {
                                setInfluencers(allInfluencers);
                                setIsSearching(false);
                            }
                        }}
                        searchCallback={value => {
                            if(value.length > 0) searchInfluencers(value);
                        }}
                    />
                </FlexEnd>
            </Line>
                {isLoading ? (
                    <BounceSpinner />
                ) : (
                    <>
                        {displayedInfluencers.length > 0 ? (
                            <>
                                <Table>
                                    <Tbody>
                                        {displayedInfluencers.map(influencer => {
                                            return (
                                                <Tr key={influencer.id} onClick={() => navigate(`/influencer/${influencer.id}`)}>
                                                    <Td>
                                                        <LineStart>
                                                            <Image src={influencer.avatarInfo?.cdnUrl || userImg} alt="Foto de perfil" />
                                                            <span>
                                                                {influencer.usernameKard}
                                                            </span>
                                                        </LineStart>
                                                    </Td>
                                                    <Td>
                                                        {influencer.name}
                                                    </Td>
                                                    <Td>
                                                        {influencer.email}
                                                    </Td>
                                                    <Td>
                                                        {influencer.phone}
                                                    </Td>
                                                    <Td align="right">
                                                        <TagStatus color={colorsStatus[influencer.status]}>
                                                            {influencer.status}
                                                        </TagStatus>
                                                    </Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>

                                {!isSearching && (
                                    <Pagination
                                        activePage={activePage}
                                        totalItemsCount={totalInfluencers}
                                        onChange={(page) => setActivePage(page)}
                                    />
                                )}
                            </>
                        ) : (
                            <NotFoundItems>
                                Nenhum influencer encontrado
                            </NotFoundItems>
                        )}
                    </>
                )}
        </Box>
    )
};

export default InfluencersPage;