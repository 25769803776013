import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    background-color: transparent;

    display: flex;
    gap: 5px;
    min-width: 250px;
    max-width: 300px;
    justify-content: space-between;

    i {
        transform: rotate(270deg);
    }

    @media(max-width: 768px) {
        width: 100%;
        height: 45px;
    }
`;

export const StyledInput = styled.input`
    color: #fff;
    font-size: 14px;
    font-family: Cerebri Sans;
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    padding: 8px 12px;
    border-radius: 10px 0 0 10px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(255, 255, 255, .5) !important;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba(255, 255, 255, .5) !important;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: rgba(255, 255, 255, .5) !important;
    }
    
    &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, .5) !important;
    }
    
    &::-moz-placeholder {
        color: rgba(255, 255, 255, .5) !important;
    }
`;

export const Button = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    width: fit-content;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 0 10px 10px 0;
    padding: 8px 12px;
`;