import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
`;

export const Line = styled.div`
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
    align-items: center;

    & + & {
        margin-top: 20px;
    }

    @media(max-width: 920px) {
        gap: 30px;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .uploadcare--widget__button_type_open, .uploaded-file {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        white-space: break-spaces;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
`;

export const ProfilePicture = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #fff;
`;

export const Group = styled(Line)`
    gap: 20px;
    flex-wrap: nowrap;

    .input {
        width: 280px;
        flex: 1;
    }

    & + & {
        margin-top: 20px;
    }
`;

export const LineNoWrap = styled(Line)`
    align-items: flex-start;
`;

export const ColumnFlex = styled(Column)`
    flex: 1;

    h2 {
        margin: 20px 0 20px;
    }
`;