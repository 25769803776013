import api from "../../../services/index";

export const uploadFile = async (file) => {
  const form = new FormData();

  form.append("file", file);

  const apiFile = await api.post("/media/upload", form, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  const response = {
    mediaUrl: apiFile.data.mediaUrl,
    uuid: apiFile.data.uuid,
  };

  return response;
};
