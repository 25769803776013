import React, { useMemo } from 'react';
import Select from 'react-select/async';

const AsyncSelect = ({ 
    id,
    isMulti, 
    loadOptions, 
    value, 
    onChange, 
    noOptionsMessage, 
    placeholder, 
    onInputChange 
}) => {
    const customStyles = useMemo(() => {
        return {
            container: (base, state) => ({
                ...base,
                flex: 1,
                fontFamily: "Cerebri Sans",
                border: state.isFocused ? null : null,
                color: "white",
            }),
            input: (base, state) => ({
                ...base,
                color: "white",
            }),
            option: (base, state) => ({
                ...base,
                background: "var(--black-color)",
                ":hover": {
                    backgroundColor: "#171717",
                },
            }),
            menu: (base, state) => ({
                ...base,
                background: "var(--black-color)",
            }),
            multiValue: (base, state) => ({
                ...base,
                background: "var(--secondary-color)",
                "& > div": {
                    color: "#fff",
                }
            }),
            singleValue: (base, state) => ({
                ...base,
                background: "transparent",
                color: "#fff",
            }),
            control: (base, state) => ({
                ...base,
                minHeight: "45px",
                background: "var(--black-color)",
                borderColor: "var(--black-color)",
                border: "none",
                outline: "none",
                boxShadow: "none",
                minWidth: "280px",
                ":hover": {
                    border: "none"
                },
            }),
            valueContainer: (base, state) => ({
                ...base,
                background: "var(--black-color)",
                paddingLeft: "15px"
            }),
            indicatorSeparator: (styles) => ({
                display: "none"
            }),
            dropdownIndicator: base => ({
                ...base,
                paddingRight: "15px",
                svg: {
                    width: "1rem",
                    height: "1rem",
                }
            })
        }
    }, []);

    return (
        <Select
            id={id}
            cacheOptions
            isMulti={isMulti}
            loadOptions={loadOptions}
            defaultOptions
            value={value}
            onChange={onChange}
            noOptionsMessage={() => noOptionsMessage || 'Nenhum item encontrado.'}
            loadingMessage={() => 'Pesquisando...'}
            placeholder={placeholder}
            onInputChange={onInputChange}
            styles={customStyles}
        />
    );
};

export default AsyncSelect;