import React from 'react';
import { Container, Option } from './style';

const Toggle = ({ id, options, onChange, value, disabled, color }) => {
    return (
        <Container id={id}>
            {options.length === 2 && options.map(option => {
                const optionId = `${id}-${option.id}`;
                const isSelected = option.value === value;

                return (
                    <Option 
                        id={optionId}
                        key={optionId}
                        selected={isSelected}
                        disabled={disabled}
                        color={color}
                        onClick={() => {
                            if(isSelected || disabled) return;

                            onChange(option.value);
                        }}
                    >
                        {option.label}
                    </Option>
                )
            })}
        </Container>
    )
};

export default Toggle;