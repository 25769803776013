import styled, { css } from 'styled-components';

const disabledStyle = css`
    cursor: default;
    opacity: .3;

    input:checked ~ .checkmark:after {
        background-color: transparent;
    }
`;

export const Container = styled.label`
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 16px;
    font-family: 'Cerebri Sans';
    letter-spacing: 0px;
    color: #fff;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    
    input:checked ~ .checkmark:after {
        display: block;
    }

    input[type="checkbox"] ~ .checkmark {
        border-radius: 2px;

        &:after {
            border-radius: 1px;
        }
    }

    input[type="radio"] ~ .checkmark {
        border-radius: 50%;

        &:after {
            border-radius: 50%;
        }
    }

    ${({ disabled }) => disabled && disabledStyle};
`;

export const Checkmark = styled.span`
    position: absolute;
    /* top: calc(50% - 9px); */
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid #fff;
    background-color: transparent;
    
    :after {
        content: "";
        position: absolute;
        display: none;
        left: 2px;
        top: 2px;
        width: 8px;
        height: 8px;
        background-color: var(--primary-color);
    }
`;