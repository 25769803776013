import React from "react";
import { arrayMove } from "react-sortable-hoc";
import { Button, BackgroundBox, Content, ContentBox, Header } from './styles';
import { Sortable } from "../sortable";

export function MultipleUpload({
  handleToggleMouseDragArea,
  handleChangeInput,
  onDropFileLabel,
  isOpen,
  setIsOpen, 
  filesUploaded,
  setFilesUploaded,
  isMouseOnDragArea,
  setIsMouseOnDragArea,
  onFileUploaded,
  children,
  isToAddBorderAnimate,
  maxFiles = 1
  }) {
  const inputRef = React.useRef(null);

  function onSortEnd({ oldIndex, newIndex }) {
    setFilesUploaded(prev => arrayMove(prev, oldIndex, newIndex));
  }
  
  function openFileExplorer() {
    inputRef.current.click();
  }

  function onConfirmFiles() {
    onFileUploaded(filesUploaded);

    handleToggleOpenModal();
  }

  const cleanUpUploadedFiles = React.useCallback(() => {
    setFilesUploaded([]);
    setIsMouseOnDragArea(false);

    if (inputRef.current) {
      inputRef.current.value = null;
    }
  }, [setFilesUploaded, setIsMouseOnDragArea]);

  const handleToggleOpenModal = React.useCallback(() => {
    setIsOpen(prev => !prev);
  }, [setIsOpen]);

  const handleToggleCloseModal = React.useCallback(() => {
    if (!isOpen) return;

    handleToggleOpenModal();
  }, [isOpen, handleToggleOpenModal]);

  const handleDeleteFile = React.useCallback((id) =>{
    const files = filesUploaded.filter(f => f.id !== id);

    setFilesUploaded(files);
  }, [filesUploaded, setFilesUploaded]);

  React.useEffect(() => {
    function handleCloseModal(e) {
      if (e.key === 'Escape') handleToggleCloseModal();
    }

    document.addEventListener('keydown', handleCloseModal);

    return () => {
      document.removeEventListener('keydown', handleCloseModal);
    };
  }, [isOpen, handleToggleCloseModal]);

  return (
    <>
      <Button
        data-testid="choose-image-button"
        type="button"
        onClick={handleToggleOpenModal}
        onMouseEnter={handleToggleMouseDragArea}
        onMouseLeave={handleToggleMouseDragArea}
        className={
          isMouseOnDragArea && isToAddBorderAnimate ? "add-border-animate" : ""
        }
      >
        {children}
      </Button>

      {isOpen && (
        <BackgroundBox data-testid="modal-background-box">
          <ContentBox>
            <Header>
              <button
                type="button"
                onClick={handleToggleCloseModal}
                data-testid="close-modal-button"
              >
                x
              </button>
            </Header>
            <Content isSomeFileUploaded={filesUploaded.length > 0}>
              <input
                type="file"
                name="multiple-upload"
                id="multiple-upload"
                ref={inputRef}
                multiple
                onChange={handleChangeInput}
                data-testid="uploader-input"
              />
              <div>
                {filesUploaded.length > 0 && (
                  <>
                    <Sortable
                      items={filesUploaded}
                      onSortEnd={onSortEnd}
                      handleDeleteFile={handleDeleteFile}
                    />
                    <footer className="buttons-form">
                      <button
                        type="button"
                        className="material-bubble cancel-button"
                        onClick={cleanUpUploadedFiles}
                        data-testid="cancel-button"
                      >
                        <span>Cancelar</span>
                      </button>
                      <button
                        type="button"
                        className="material-bubble"
                        onClick={onConfirmFiles}
                        data-testid="submit-button"
                      >
                        <span>Enviar</span>
                      </button>
                    </footer>
                  </>
                )}
                {console.log(
                  "filesUploaded len",
                  filesUploaded.length,
                  "maxFiles",
                  maxFiles,
                  filesUploaded.length < maxFiles
                )}

                {filesUploaded.length < maxFiles && (
                  <>
                    {filesUploaded.length && <hr />}
                    <label
                      htmlFor="multiple-upload"
                      onDragEnter={handleToggleMouseDragArea}
                      onDragLeave={handleToggleMouseDragArea}
                      className={isMouseOnDragArea ? "add-border-animate" : ""}
                      onDrop={onDropFileLabel}
                      data-testid="multiple-upload-label"
                    >
                      {filesUploaded.length ? (
                        <h3>Arraste um arquivo aqui</h3>
                      ) : (
                        <h2>Arraste um arquivo aqui</h2>
                      )}
                      <p>ou</p>
                    </label>
                    <button
                      type="button"
                      className="material-bubble"
                      onClick={openFileExplorer}
                    >
                      <span>Escolha um arquivo do computador</span>
                    </button>
                  </>
                )}
              </div>
            </Content>
          </ContentBox>
        </BackgroundBox>
      )}
    </>
  );
}
