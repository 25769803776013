import styled from "styled-components";

export const Container = styled.figure`
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 1rem;
  max-width: 500px;
  border-radius: 0.35rem 0.35rem 0 0;
  background: ${props => props.background || 'gray'};
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100000;
  animation: opacity 1s ease-in-out;

  img {
    max-width: 35px;
  }

  figcaption {
    overflow-y: auto;

    & p:first-child {
      font-weight: bold;
      font-size: 1rem;
    }

    & p:last-child {
      font-size: 0.75rem;
    }
  }

  &.animation-out {
    animation: reverse-opacity 1s ease-in-out;
  }

  @keyframes opacity {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

  @keyframes reverse-opacity {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
`;