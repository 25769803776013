import styled from 'styled-components';

export const Table = styled.table`
    border: none;
    border-collapse: separate; 
    border-spacing: 0 10px;
`;

export const Thead = styled.thead`
    &:first-child th:last-child { 
        padding-right: 4px;
    }
`;

export const Tbody = styled.tbody`
`;

export const Tr = styled.tr`
    background-color: var(--table-row-color);

    td:first-child { 
        border-top-left-radius: 7px; 
        border-bottom-left-radius: 7px;
    }
    td:last-child { 
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }

    ${({ onClick }) => onClick && 'cursor: pointer;'}
`;

export const Th = styled.th`
    font-size: 18px;
    font-family: Cerebri Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    text-align: ${({ align }) => align ? align : 'left'};

    div {
        display: flex;
        align-items: center;
        white-space: nowrap;

        span {
            padding: 0 10px;

            &.pl4 {
                padding-left: 4px;
            }
        }
    }
`;

export const Td = styled.td`
    font-size: 16px;
    font-weight: 400;
    font-family: 'Cerebri Sans';
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 4px 10px;
    text-align: ${({ align }) => align ? align : 'left'};

    &.pl4 {
        padding-left: 4px;
    }

    i {
        font-size: 20px;
        cursor: pointer;
    }

    @media(max-width: 768px) {
        &.nobreak {
            white-space: nowrap;
        }
    }
`;

export const Image = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    object-fit: cover;
    background-color: #fff;
`;