class InputMasks {
    cpf = () => {
        return [
            /\d/, /\d/, /\d/, ".",
            /\d/, /\d/, /\d/, ".",
            /\d/, /\d/, /\d/, "-",
            /\d/, /\d/,
        ];
    };

    cnpj = () => {
        return [
            /\d/, /\d/, ".",
            /\d/, /\d/, /\d/, ".",
            /\d/, /\d/, /\d/, "/", 
            /\d/, /\d/, /\d/, /\d/, "-",
            /\d/, /\d/,
        ];
    };

    phone = () => {
        return [
            "(", /\d/, /\d/, ")", " ",
            /\d/, /\d/, /\d/, /\d/, /\d/, "-",
            /\d/, /\d/, /\d/, /\d/,
        ];
    };
};

export default new InputMasks();