import React, { useRef, useCallback } from 'react';
import { SearchIcon } from 'react-line-awesome';
import { Container, StyledInput, Button } from './style';

const SearchInput = ({ 
    id,
    onChange,
    placeholder,
    searchCallback,
}) => {
    const inputRef = useRef(null);

    const handleKeyPress = useCallback((evt) => {
        const code = evt.key;
    
        if (!searchCallback) return;
    
        if (inputRef.current?.value.length) {
          if (code === 'Enter') {
            evt.preventDefault();
    
            searchCallback(inputRef.current?.value || "");
          }
        }
      }, [searchCallback, inputRef]);

    return (
        <Container>
            <StyledInput 
                id={id}
                ref={inputRef}
                placeholder={placeholder}
                onKeyPress={handleKeyPress}
                onChange={(e) => onChange(e)}
            />

            <Button 
                type="button"
                onClick={() => searchCallback(inputRef.current?.value)}
            >
                <SearchIcon />
            </Button>
        </Container>
    )
};

export default SearchInput;