import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { Toast } from "./components/Toast";
import { MultipleUpload } from "./components/multiple-upload";
import { SingleUpload } from "./components/single-upload";

export const Uploader = ({ 
  isSingleUpload,
  children,
  onFileUploaded,
  isToAddBorderAnimate,
  filesUploaded: filesUploadedProp = [],
  onlyImages = false,
  maxFiles
}) => {

  const VIDEO_EXTENSIONS = ['video/mp4', 'video/ogg', 'video/webm'];
  const IMAGE_EXTENSIONS = ['image/jpeg', 'image/png', 'image/webp'];
  const [isOpen, setIsOpen] = React.useState(false);
  const [filesUploaded, setFilesUploaded] = React.useState([]);
  const [validation, setValidation] = React.useState({ isOpen: false, message: '' });
  const [isMouseOnDragArea, setIsMouseOnDragArea] = React.useState(false);
  
  useEffect(() => {
    if (filesUploadedProp.length) {
      console.log("filesUploadedProp", filesUploadedProp);
      setFilesUploaded(filesUploadedProp);
    }
  }, [filesUploadedProp]);

  const customProps = {
      handleToggleMouseDragArea,
      isMouseOnDragArea,
      onFileUploaded,
      onDropFileLabel,
      filesUploaded,
      handleChangeInput,
      isToAddBorderAnimate,
    };

  function showErrorMessageFileTypeValidation(notAllowedFiles) {
    const filesName = notAllowedFiles.map(file => file.name).join(', ').concat('.');

    setValidation({
      isOpen: true,
      message: `Não permitido arquivos: ${filesName}`,
    });
  }

  function validateFileType(files) {
    const ALLOWED_FILES_EXTENSION = onlyImages ? IMAGE_EXTENSIONS.join(' ') : [...IMAGE_EXTENSIONS, ...VIDEO_EXTENSIONS].join(' ');
    const allowedFiles = [];
    const notAllowedFiles = [];

    files.forEach(file => {
      if (ALLOWED_FILES_EXTENSION.includes(file.type)) allowedFiles.push(file);
      else notAllowedFiles.push(file);
    });

    return [allowedFiles, notAllowedFiles];
  }

  function handleToggleMouseDragArea() {
    setIsMouseOnDragArea(prev => !prev);
  }

  function handleChangeInput(e) {
    const files = Array.from(e.target.files);

    const [allowedFiles, notAllowedFiles] = validateFileType(files);

    if (notAllowedFiles.length > 0) showErrorMessageFileTypeValidation(notAllowedFiles);

    if (allowedFiles.length <= 0) return;

    const newFilesUploaded = allowedFiles.map(file => ({
      file,
      id: uuid(),
      preview: '',
    }));

    handlePreviewFiles(newFilesUploaded);
  }

  function onDropFileLabel(e) {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    handleChangeInput({ target: { files } });
  }

  function removeToastFromScreen() {
    setValidation({
      isOpen: false,
      message: '',
    });
  }

  function createPreviewImage(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      }
      reader.readAsDataURL(file);
    });
  }

  const handlePreviewFiles = React.useCallback(uploadedFiles => {
    Promise.all(uploadedFiles.map(({ file }) => {
      if (VIDEO_EXTENSIONS.includes((file || {}).type)) return (window.URL || window.webkitURL).createObjectURL(file);
      return createPreviewImage(file);
    })).then(values => {
      const updatedFiles = uploadedFiles.map((file, index) => ({
          ...file,
          preview: values[index],
      }));
      
      setFilesUploaded(prev => [
        ...prev,
        ...updatedFiles,
      ]);
    });
  }, [setFilesUploaded]);

  React.useEffect(() => {
    window.addEventListener("dragover", (e) => {
      e.preventDefault();
    }, false);

    return () => {
      window.removeEventListener("dragover", (e) => {
        e.preventDefault();
      }, false);
    };
  }, []);

  return (
    <>
      {isSingleUpload ? (
        <SingleUpload filesUploaded={filesUploaded} {...customProps}>
          {children}
        </SingleUpload>
      ) : (
        <MultipleUpload
          {...customProps}
          filesUploaded={filesUploaded}
          setFilesUploaded={setFilesUploaded}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setIsMouseOnDragArea={setIsMouseOnDragArea}
          maxFiles={maxFiles}
        >
          {children}
        </MultipleUpload>
      )}

      {validation.isOpen && (
        <Toast
          toastType="ERROR"
          message={validation.message}
          removeToast={removeToastFromScreen}
        />
      )}
    </>
  );
}
