import React from 'react';
import { Container, Checkmark } from './style';

const Checkbox = ({ 
    id, 
    name,
    value,
    label,
    checked,
    disabled,
    onChange,
  }) => {
    return (
        <Container checked={checked} disabled={disabled}>
            {label}
            <input
                id={id}
                name={name}
                value={value}
                type="checkbox" 
                disabled={disabled}
                checked={checked} 
                onChange={onChange}
            />
            <Checkmark className="checkmark" />
        </Container>
    );
}

const Radio = ({ 
    id, 
    name,
    label, 
    value,
    checked,
    disabled,
    onChange
  }) => {
    return (
        <Container disabled={disabled}>
            {label}
            <input
                id={id}
                name={name}
                type="radio"
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <Checkmark className="checkmark" />
        </Container>
    );
}

export { Checkbox, Radio };