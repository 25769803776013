import React, { useState, useEffect } from "react";
import { FaPowerOff, FaGripHorizontal, FaStore, FaRegUser } from "react-icons/fa";
import logoK from "../../assets/images/logo_kuak_k_icn.svg";
import logoKuak from "../../assets/images/kuak_logo_branco.svg";
import { getUserProperties } from '../../services/auth';
import { 
    NavMenu, 
    NavMenuItems, 
    NavText, 
    NavTextLink, 
    NavTextTitle, 
    MainContainer,
    LogoContainer,
    LogoImg,
    ProfileImg
} from "./style";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FaGripHorizontal />,
    backgroundColor: "#f3f4f8",
  },
  {
    title: "Marcas",
    path: "/brands",
    icon: <FaStore />,
    backgroundColor: "#f3f4f8",
  },
  {
    title: "Influencers",
    path: "/influencers",
    icon: <FaRegUser />,
    backgroundColor: "#f3f4f8",
  },
];

const SideBar = ({children}) => {
  const [profileImg, setProfileImg] = useState('');

  useEffect(() => {
    async function getImage() {
      const properties = await getUserProperties().then(res => res);

      const profileUrl = properties.profileUrl || properties.thumbPhoto || properties.socialPhoto || this.state.profileLogo;

      setProfileImg(profileUrl);
    }

    getImage();
  }, []);

  return (
    <>
      <NavMenu>
        <NavMenuItems>
          <LogoContainer>
            <LogoImg 
              id="logo_k"
              src={logoK}
              alt="Logo da Kuak"
            />
            <LogoImg 
              id="logo_kuak"
              src={logoKuak}
              alt="Logo da Kuak"
              className="hidden"
            />
          </LogoContainer>

          {SidebarData.map((item, index) => {
            return (
              <NavText key={index}>
                <NavTextLink
                  to={item.path}
                  className="expandable"
                >
                  {item.icon}
                  <NavTextTitle>{item.title}</NavTextTitle>
                </NavTextLink>
              </NavText>
            );
          })}
        </NavMenuItems>
        <NavMenuItems>
          <NavText>
            <NavTextLink
              key="profile"
              to='/profile'
              className="expandable"
            >
              <ProfileImg src={profileImg} alt="Foto de perfil" />
              <NavTextTitle>Minha Conta</NavTextTitle>
            </NavTextLink>
          </NavText>
          <NavText key="logout">
            <NavTextLink
              to="/logout"
              className="expandable"
            >
              <FaPowerOff />
              <NavTextTitle>Sair</NavTextTitle>
            </NavTextLink>
          </NavText>
        </NavMenuItems>
      </NavMenu>

      <MainContainer>
        {children}
      </MainContainer>
    </>
  );
};

export default SideBar;