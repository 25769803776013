import styled from 'styled-components';

export const StyledSelect = styled.select`
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 8px 12px;
    background-color: transparent;
    color: #FFFFFF;
    font-size: 14px;
    font-family: Cerebri Sans;
    text-transform: uppercase;
    min-width: 200px;
    max-width: 250px;

    @media(max-width: 768px) {
        height: 45px;
        max-width: 100%;
    }
`;

export const Option = styled.option`
    color: #FFFFFF;
    font-size: 12px;
    font-family: Cerebri Sans;
    text-transform: uppercase;
`;