import React from 'react';
import { Container, Select, ErrorMessage } from './style';

const SelectDefault = ({ 
    id,
    value,
    type,
    placeholder,
    onChange,
    small,
    variant,
    leftText,
    errorMessage,
    showErrorMessage,
    options,
}) => {
    return (
        <Container 
            isSmall={small}
            className="input"
            showErrorMessage={showErrorMessage} 
            variant={variant}
        >
            {leftText}

            <Select 
                id={id}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={e => onChange(e.target.value)}
            >
                <option selected value="" disabled>
                    {placeholder || "Selecione"}
                </option>

                {options && options.map(option => {
                    return (
                        <option key={option.key} value={option.value}>
                            {option.label}
                        </option>
                    )
                })}
            </Select>

            {showErrorMessage && (
                <ErrorMessage>
                    {errorMessage}
                </ErrorMessage>
            )}
        </Container>
    )
};

export default SelectDefault;