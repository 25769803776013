import styled from 'styled-components';

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1.5fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    width: 100%;
    height: calc(100vh - 60px);
`;

export const Box = styled.div`
    background-color: var(--secondary-color);
    border-radius: 9px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const BoxGrafico = styled(Box)`
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
`;

export const BoxTitle = styled.h2`
    font-size: 20px;
    font-weight: 400;
    font-family: Quicksand;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin: 0;
`;

export const Text = styled.span`
    font-size: 14px;
    font-family: Quicksand;
    letter-spacing: 0px;
    color: #FFFFFF;
    line-height: initial;
`;

export const Value = styled(Text)`
    font-size: 45px;
    font-weight: 500;
`;

export const Group = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const GraphValue = styled(Text)`
    font-weight: 500;
    font-size: 22px;
    letter-spacing: -0.87px;
`;

export const Line = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5px;
`;

export const FlexEnd = styled.div`
    display: flex;
    align-items: baseline;
    gap: 15px;
`;