import styled from 'styled-components';
import { Image as GSImage } from '../../components/global-style';

export const Box = styled.div`
    background-color: var(--secondary-color);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-self: flex-start;
    padding: 22px 28px;
    width: 100%;
    gap: 20px;
    flex: 1;
`;

export const Line = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
`;

export const FlexEnd = styled.div`
    display: flex;
    align-items: baseline;
    gap: 15px;
`;

export const LineStart = styled(Line)`
    justify-content: flex-start;
`;

export const Image = styled(GSImage)`
    border-radius: 4px;
    border-color: var(--influencer-color);
`;