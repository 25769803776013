import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: #fff;
    height: 100%;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    background-color: var(--black-color);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
`;

export const Panel = styled.div`
    display: flex;
    flex-direction: column;
    padding: 120px 80px 40px;
    background-color: var(--secondary-color);
    flex: 1;
`;

export const Group = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
`;

export const Title = styled.h1`
    font-size: 500;
    font-size: 16px;
    margin: 0;
    font-family: 'Cerebri Sans';
`;

export const ButtonGoBack = styled(Link)`
    color: #fff;
    font-size: 24px;
    text-decoration: none;

    :hover {
        color: #fff;
        opacity: .8;
    }
`;