import api from './index';

/**
 * 
 * @param {number} limit limite de resultados
 * @param {number} offset quantos resultados 'pular'
 */
export const getAllInfluencers = (limit, offset) => {
  return api.get(`/publisher/paginado/${limit}/${offset}`);
}

export const searchInfluencersByName = (text) => {
  return api.get(`/users/byname/PUBLISHER/${text}`);
}

export const getInfluencerById = (influencerId) => {
  return api.get(`/publisher/show/${influencerId}`);
}

export const updateInfluencer = (influencer) => {
  const { id, ...data } = influencer;

  return api.post(`/users/changeoptions/${id}`, data);
}