import React from "react";
import { SortableElement } from "react-sortable-hoc";
import { ListItem } from "./styles";
const MAX_FILE_NAME_LENGTH = 5;

const VIDEO_EXTENSIONS = ['video/mp4', 'video/ogg', 'video/webm'];

export const SortableItem = SortableElement((props) => {
  const { value, handleDeleteFile, id } = props;
  const file = props.file || {};
  const fileName = file.name || '';
  const fileType = file.type || '';

  return (
    <ListItem data-testid="upload-item">
      <button
        onClick={(e) => {
          e.preventDefault();

          handleDeleteFile(id);
        }}
        data-testid="button-delete-item"
        type="button"
      >
        x
      </button>
      <figure>
        {VIDEO_EXTENSIONS.includes(fileType) ? (
          <video 
            alt={`Uploaded file ${file.name}`} 
            style={{ objectFit: 'cover' }}
            src={value}
            width={120}
            height={80}
            controls={false}
          >
          </video>
        ) : (
          <img 
            src={value} 
            alt={`Uploaded file ${file.name}`} 
            style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }} 
          />
        )}
        <figcaption>
          {
            fileName.length > MAX_FILE_NAME_LENGTH ? 
            fileName
            .substring(0, MAX_FILE_NAME_LENGTH)
            .concat('...')
            .concat(file.type)
            .replace('image/', '') :
            fileName
          }
        </figcaption>
      </figure>
    </ListItem>
  )
});
