import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logoKuak from "../../assets/images/kuak_logo_branco.svg";
import logoGoogle from "../../assets/images/google_authenticator_icn.png";
import poweredGoogle from "../../assets/images/powered_google_icn.png";
import alertNotify from '../../components/alert-notify';
import { verifyCodeMFA, getUserProperties, setUserProperties } from '../../services/auth';
import { 
    Container, 
    Form, 
    Logo, 
    LogoGoogle, 
    PoweredGoogle, 
    BoxInputAuth, 
    BoxInputContainer,
    Input,
    LoadingContainer
} from './style';

const TwoAuthPage = ({ navigation }) => {
    const [state, setState] = useState({
        isFormInvalid: true,
        first: '',
        firstError: '',
        second: '',
        secondError: '',
        third: '',
        thirdError: '',
        fourth: '',
        fourthError: '',
        five: '',
        fiveError: '',
        six: '',
        sixError: '',
        disabled: false,
        loading: false
    })

    const navigate = useNavigate();

    const firstInputRef = useRef(null)
    const secondInputRef = useRef(null)
    const thirdInputRef = useRef(null)
    const fourthInputRef = useRef(null)
    const fiveInputRef = useRef(null)
    const sixInputRef = useRef(null)

    const checkIsValid = (event) => {
        return !!event.length
    }

    const checkIfAnyIsInvalid = (value) => {
        return !!value.firstError || !!value.secondError || !!value.thirdError || !!value.fourthError || !!value.fiveError || !!value.sixError
    }

    const checkIfCanChange = (value) => {
        return value.length === 1 || value.length === 0
    }

    const checkIfCanGoForward = useCallback((value) => {
        return value.length === 1
    },[])

    useEffect(() => { validate('first') }, [state.first]);
    useEffect(() => { validate('second') }, [state.second]);
    useEffect(() => { validate('third') }, [state.third]);
    useEffect(() => { validate('fourth') }, [state.fourth]);
    useEffect(() => { validate('five') }, [state.five]);
    useEffect(() => { validate('six') }, [state.six]);
    useEffect(() => {
        !state.isFormInvalid && !state.loading && setState({ ...state, loading: true, disabled: true })
    }, [state.isFormInvalid])

    useEffect(() => {
        (async () => {
            !!state.loading && await handleSubmit()
        })()
    }, [state.loading])

    const validate = (field) => {
        setState(old => ({ ...old, [`${field}Error`]: state[field].length !== 1 }))
        setState(old => ({
            ...old,
            isFormInvalid: checkIfAnyIsInvalid(old)
        }))
    }

    const handleKeyDown = useCallback(({
        event, 
        actualInput, 
        previousInput, 
        previousInputSetState
    }) => {
        if(event.key === "Backspace" && actualInput.current.value.length === 0){
            previousInputSetState('');
            previousInput?.current.focus()
        }
    },[]);

    const handleSubmit = useCallback(async () => {
        const code = `${state.first}${state.second}${state.third}${state.fourth}${state.five}${state.six}`;

        try {
            const user = await getUserProperties();
            
            await verifyCodeMFA({ code, email: user.email });

            user.isAuthenticated = true;

            setUserProperties(user);

            navigate('/dashboard');

        } catch (err) {
            setState(prev => ({ 
                ...prev, 
                loading: false, 
                disabled: false,
                first: '',
                firstError: '',
                second: '',
                secondError: '',
                third: '',
                thirdError: '',
                fourth: '',
                fourthError: '',
                five: '',
                fiveError: '',
                six: '',
                sixError: '',
            }));

            const errorCode = err.response?.status;
            const error = errorCode === 401 ? 'Código Inválido.' 
                : err?.response?.data?.message || 'Ocorreu um erro ao realizar a autenticação.';

            alertNotify(error, null, 'danger');
        }
    }, [state]);

    return (
        <Container>
            <Logo src={logoKuak} alt="Logo Kuak" />
            <LogoGoogle src={logoGoogle} alt="Logo Google Authenticator" />

            <Form>
                <BoxInputContainer>
                    <BoxInputAuth active={checkIsValid(state.first)}>
                        <Input
                            active={checkIsValid(state.first)}
                            onChange={e => {
                                !!checkIfCanChange(e.target.value) && (setState({ ...state, first: e.target.value }))
                                checkIfCanGoForward(e.target.value) && secondInputRef.current.focus()
                            }}
                            maxLength={1}
                            type={'number'}
                            value={state.first}
                            autoFocus={true}
                            disabled={state.disabled}
                            ref={firstInputRef}
                            onKeyDown={(e) => handleKeyDown({event: e, 
                                previousInput: null,
                                actualInput: firstInputRef,
                                previousInputSetState: () => {return;}
                            })}
                        />
                    </BoxInputAuth>
                    <BoxInputAuth active={checkIsValid(state.second)}>
                        <Input
                            active={checkIsValid(state.second)}
                            onChange={e => {
                                !!checkIfCanChange(e.target.value) && (setState({ ...state, second: e.target.value }))
                                checkIfCanGoForward(e.target.value) && thirdInputRef.current.focus()
                            }}
                            maxLength={1}
                            type={'number'}
                            value={state.second}
                            disabled={state.disabled}
                            ref={secondInputRef}
                            onKeyDown={(e) => handleKeyDown({event: e, 
                                previousInput: firstInputRef,
                                actualInput: secondInputRef,
                                previousInputSetState: (value) => setState({ ...state, first: value }),
                            })}
                        />
                    </BoxInputAuth>
                    <BoxInputAuth active={checkIsValid(state.third)}>
                        <Input
                            active={checkIsValid(state.third)}
                            onChange={e => {
                                !!checkIfCanChange(e.target.value) && (setState({ ...state, third: e.target.value }))
                                checkIfCanGoForward(e.target.value) && fourthInputRef.current.focus()
                            }}
                            maxLength={1}
                            type={'number'}
                            value={state.third}
                            disabled={state.disabled}
                            ref={thirdInputRef}
                            onKeyDown={(e) => handleKeyDown({event: e, 
                                previousInput: secondInputRef,
                                actualInput: thirdInputRef,
                                previousInputSetState: (value) => setState({ ...state, second: value }),
                            })}
                        />
                    </BoxInputAuth>
                    <BoxInputAuth active={checkIsValid(state.fourth)}>
                        <Input
                            active={checkIsValid(state.fourth)}
                            onChange={e => {
                                !!checkIfCanChange(e.target.value) && (setState({ ...state, fourth: e.target.value }))
                                checkIfCanGoForward(e.target.value) && fiveInputRef.current.focus()
                            }}
                            maxLength={1}
                            type={'number'}
                            value={state.fourth}
                            disabled={state.disabled}
                            ref={fourthInputRef}
                            onKeyDown={(e) => handleKeyDown({event: e, 
                                previousInput: thirdInputRef,
                                actualInput: fourthInputRef,
                                previousInputSetState: (value) => setState({ ...state, third: value }),
                            })}
                        />
                    </BoxInputAuth>
                    <BoxInputAuth active={checkIsValid(state.five)}>
                        <Input
                            active={checkIsValid(state.five)}
                            onChange={e => {
                                !!checkIfCanChange(e.target.value) && (setState({ ...state, five: e.target.value }))
                                checkIfCanGoForward(e.target.value) && sixInputRef.current.focus()
                            }}
                            maxLength={1}
                            type={'number'}
                            value={state.five}
                            disabled={state.disabled}
                            ref={fiveInputRef}
                            onKeyDown={(e) => handleKeyDown({event: e, 
                                previousInput: fourthInputRef,
                                actualInput: fiveInputRef,
                                previousInputSetState: (value) => setState({ ...state, fourth: value }),
                            })}
                        />
                    </BoxInputAuth>
                    <BoxInputAuth active={checkIsValid(state.six)}>
                        <Input
                            active={checkIsValid(state.six)}
                            onChange={e => {
                                !!checkIfCanChange(e.target.value) && (setState({ ...state, six: e.target.value }))
                            }}
                            maxLength={1}
                            type={'number'}
                            value={state.six}
                            disabled={state.disabled}
                            ref={sixInputRef}
                            onKeyDown={(e) => handleKeyDown({event: e, 
                                previousInput: fiveInputRef,
                                actualInput: sixInputRef,
                                previousInputSetState: (value) => setState({ ...state, five: value }),
                            })}
                        />
                    </BoxInputAuth>
                </BoxInputContainer>

                <PoweredGoogle src={poweredGoogle} alt="Powered by Google" />

                <LoadingContainer isHidden={!state.loading}>
                    <Spinner
                        as="span" 
                        animation="border" 
                        role="status" 
                        variant="primary"
                    />
                </LoadingContainer>
            </Form>
        </Container>
    )
};

export default TwoAuthPage;