import api from './index';

export const getCampaignsByBrandId = (brandId) => {
    return api.get(`/campaign/byBrand/${brandId}`);
}

export const activateCampaign = (campaignId) => {
    return api.put(`/campaign/activate/${campaignId}`);
}

export const updateStatusCampaign = (campaignId, status) => {
    return api.patch(`/campaign/${campaignId}`, { status: status });
}