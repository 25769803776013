class Formatters {
    moneyBR = (value) => {
        const floatValue = parseFloat(value);

        return floatValue.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
    }

    date = (value) => {
        const date = new Date(value);

        const options = {
            day: '2-digit',
            month: '2-digit',
        };

        return date.toLocaleDateString('pt-BR', options);
    }

    formatNumberShort = (value) => {
        var num = 0;
        if (isNaN(value) || !value) {
          /* num = Number.parseFloat(value); */
          return 0;
        } else {
          num = parseFloat(value);
        }
    
        var repString, repDecimal, r;
        if (num < 1000) {
          repString = num;
        } else if (num < 10000) {
          num = String(num);
          repDecimal = Math.round(num / 100) / 10;
          repString = repDecimal + "k";
        } else if (num < 1000000) {
          repDecimal = Math.round(num / 100) / 10;
          repString = repDecimal + "k";
        } else {
          repDecimal = Math.round(num / 100000) / 10;
          var n;
          if (repDecimal.toString().split(".").length > 1) {
            n =
              repDecimal.toString().split(".")[0] +
              "." +
              repDecimal.toString().split(".")[1] +
              "M";
          } else {
            n = repDecimal.toString().split(".")[0] + "M";
          }
          repString = n;
        }
    
        if (repString != null) {
          return repString.toString();
        } else {
          return 0;
        }
    };
};

export default new Formatters;