const estados = [
  {
    id: "AC",
    value: "AC",
    name: "Acre",
  },
  {
    id: "AL",
    value: "AL",
    name: "Alagoas",
  },
  {
    id: "AP",
    value: "AP",
    name: "Amapá",
  },
  {
    id: "AM",
    value: "AM",
    name: "Amazonas",
  },
  {
    id: "BA",
    value: "BA",
    name: "Bahia",
  },
  {
    id: "CE",
    value: "CE",
    name: "Ceará",
  },
  {
    id: "DF",
    value: "DF",
    name: "Distrito Federal",
  },
  {
    id: "ES",
    value: "ES",
    name: "Espírito Santo",
  },
  {
    id: "GO",
    value: "GO",
    name: "Goiás",
  },
  {
    id: "MA",
    value: "MA",
    name: "Maranhão",
  },
  {
    id: "MT",
    value: "MT",
    name: "Mato Grosso",
  },
  {
    id: "MS",
    value: "MS",
    name: "Mato Grosso do Sul",
  },
  {
    id: "MG",
    value: "MG",
    name: "Minas Gerais",
  },
  {
    id: "PA",
    value: "PA",
    name: "Pará",
  },
  {
    id: "PB",
    value: "PB",
    name: "Paraíba",
  },
  {
    id: "PR",
    value: "PR",
    name: "Paraná",
  },
  {
    id: "PE",
    value: "PE",
    name: "Pernambuco",
  },
  {
    id: "PI",
    value: "PI",
    name: "Piauí",
  },
  {
    id: "RJ",
    value: "RJ",
    name: "Rio de Janeiro",
  },
  {
    id: "RN",
    value: "RN",
    name: "Rio Grande do Norte",
  },
  {
    id: "RS",
    value: "RS",
    name: "Rio Grande do Sul",
  },
  {
    id: "RO",
    value: "RO",
    name: "Rondônia",
  },
  {
    id: "RR",
    value: "RR",
    name: "Roraima",
  },
  {
    id: "SC",
    value: "SC",
    name: "Santa Catarina",
  },
  {
    id: "SP",
    value: "SP",
    name: "São Paulo",
  },
  {
    id: "SE",
    value: "SE",
    name: "Sergipe",
  },
  {
    id: "TO",
    value: "TO",
    name: "Tocantins",
  },
];

export default estados;
