import React from 'react';
import styled from 'styled-components';

const FormSubtitle = ({ children }) => {
    return (
        <Title>
            {children}
        </Title>
    )
};

export default FormSubtitle;

const Title = styled.h2`
    font-weight: bold;
    font-size: 20px;
    font-family: Cerebri Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
`;