import React, { useState, useEffect, useCallback } from "react";
import { TimesIcon } from "react-line-awesome";
import Input from "../../components/input";
import PageTitle from "../../components/page-title";
import Button from "../../components/button-login";
import {uploadFile} from "../../components/uploader/helpers/uploadToApi";
import {Uploader} from "../../components/uploader";
import alertNotify from "../../components/alert-notify";
import { getItem } from "../../services/localStorage";
import {
  getUserProperties,
  setUserProperties,
  enableMFA,
  getUserQrCode,
} from "../../services/auth";
import {
  updatePassword,
  updateUserInformation,
  saveNewProfilePic,
} from "../../services/user";
import {
  Container,
  Image,
  Icon,
  Relative,
  ButtonRemoveImg,
  Line,
  ColumnMFA,
  ContentQrCode,
  BoxQrCode,
  QrCodeImg,
  Text,
} from "./style";

const ProfilePage = ({ navigation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [isEnablingMFA, setIsEnablingMFA] = useState(false);
  const [user, setUser] = useState({
    name: "",
    lastname: "",
    email: "",
    profileUrl: "",
    enable_2fa: "0",
  });
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
    oldPassword: "",
  });
  const [errors, setErrors] = useState({
    email: null,
    newPassword: null,
    oldPassword: null,
    confirmPassword: null,
  });
  const [qrcode, setQrcode] = useState(null);

  const handleUploadProfilePic = useCallback(async (file) => {
    if (!file) {
      return;
    }

    const resp = await uploadFile(file);
    const info = {
      cdnUrl: resp.mediaUrl,
      uuid: resp.uuid,
      name: file.name,
      originalUrl: resp.mediaUrl,
    };

    await saveNewProfilePic({
      logoInfo: info,
    })
      .then((response) => {
        const properties = JSON.parse(getItem("userProperties"));
        properties.profileUrl = info.cdnUrl;
        properties.avatarInfo = { cdnUrl: info.cdnUrl };

        setUserProperties(properties);

        setUser((prev) => {
          return {
            ...prev,
            profileUrl: info.cdnUrl,
          };
        });
      })
      .catch((err) => window.bugsnagClient.notify(err))
      .finally(() => setIsLoading(false));
  }, []);

  const updateUserPassword = useCallback(async (passwords) => {
    setIsLoading(true);

    const { oldPassword, newPassword, confirmPassword } = passwords;
    if (
      oldPassword !== "" &&
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword === confirmPassword
    ) {
      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };

      await updatePassword(data)
        .then((response) => {
          if (response.data.success) {
            alertNotify(
              "Sua senha foi atualizada com sucesso!",
              null,
              "success"
            );
          } else {
            alertNotify(
              "Ocorreu um erro ao atualizar sua senha.",
              null,
              "danger"
            );
          }
        })
        .catch((err) => {
          const error =
            err?.response?.data === "Passwords do not match"
              ? "As senhas informadas não são iguais."
              : "Ocorreu um erro ao atualizar sua senha.";

          setErrors((prev) => {
            return {
              ...prev,
              newPassword: error,
            };
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          confirmPassword: "As senhas informadas não são iguais.",
        };
      });
      setIsLoading(false);
    }
  }, []);

  const getQrCode = useCallback(async () => {
    const user_qrcode = await getUserQrCode()
      .then(({ data }) => data.qrcode)
      .catch(() => {
        alertNotify("Ocorreu um erro ao recuperar seu QRCode", null, "danger");
      });

    if (user_qrcode) {
      setQrcode(user_qrcode);
    }
  }, []);

  const handleEnableMFA = useCallback(async () => {
    setIsEnablingMFA(true);

    await enableMFA()
      .then((response) => {
        const user_qrcode = response.data.raw[0]?.qrcode;

        if (user_qrcode) {
          setUser((prev) => ({ ...prev, enable_2fa: "1" }));
          setQrcode(user_qrcode);

          const properties = JSON.parse(getItem("userProperties"));
          properties.qrcode = user_qrcode;

          setUserProperties(properties);
        } else {
          alertNotify(
            "Ocorreu um erro ao habilitar a autenticação de 2 fatores em sua conta.",
            null,
            "danger"
          );
        }
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message ||
          "Ocorreu um erro ao habilitar a autenticação de 2 fatores em sua conta.";

        alertNotify(error, null, "danger");
      })
      .finally(() => setIsEnablingMFA(false));
  }, []);

  const submit = useCallback(async (e, user, passwords) => {
    e.preventDefault();

    const { profileUrl, ...rest } = user;

    if (passwords.newPassword) {
      await updateUserPassword(passwords);
    }

    if (!!rest.email) {
      setIsLoading(true);

      await updateUserInformation(rest)
        .then(({ data }) => {
          alertNotify(
            "Seus dados foram atualizados com sucesso!",
            null,
            "success"
          );

          const properties = JSON.parse(getItem("userProperties"));
          properties.name = rest.name;
          properties.lastname = rest.lastname;

          setUserProperties(properties);
        })
        .catch((err) => {
          alertNotify(
            "Ocorreu um erro ao atualizar seus dados.",
            null,
            "danger"
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          email: "Seu e-mail não pode ser vazio.",
        };
      });
    }
  }, []);

  useEffect(() => {
    async function getUser() {
      const { name, lastname, email, profileUrl, enable_2fa, ...rest } =
        await getUserProperties()
          .then((res) => res)
          .catch(() => null);

      setUser({ name, lastname, email, profileUrl, enable_2fa });

      if (enable_2fa === "1") {
        getQrCode();
      }
    }

    getUser();
  }, []);

  return (
    <Line>
      <Container onSubmit={(e) => submit(e, user, passwords)}>
        <PageTitle>Minha Conta</PageTitle>

        {!user?.profileUrl ? (
          <>
            <Uploader
              isSingleUpload
              onlyImages
              onFileUploaded={(file) => handleUploadProfilePic(file.file)}
              //TODO - clearable
            >
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 100,
              }}>
              Escolha uma foto
              </div>
            </Uploader>
          </>
        ) : (
          <Relative>
            <Image src={user.profileUrl} alt="Foto de perfil" />

            <ButtonRemoveImg
              type="button"
              onClick={() =>
                setUser((prev) => {
                  return {
                    ...prev,
                    profileUrl: "",
                  };
                })
              }
            >
              <TimesIcon />
            </ButtonRemoveImg>
          </Relative>
        )}

        <Input
          id="name"
          type="text"
          placeholder="Nome"
          value={user.name}
          onChange={(value) =>
            setUser((prev) => {
              return {
                ...prev,
                name: value,
              };
            })
          }
        />

        <Input
          id="lastname"
          type="text"
          placeholder="Sobrenome"
          value={user.lastname}
          onChange={(value) =>
            setUser((prev) => {
              return {
                ...prev,
                lastname: value,
              };
            })
          }
        />

        <Input
          id="email"
          type="email"
          placeholder="E-mail"
          value={user.email}
          variant="light"
          onChange={(value) => {
            setUser((prev) => {
              return {
                ...prev,
                email: value,
              };
            });
            setErrors((prev) => {
              return {
                ...prev,
                email: null,
              };
            });
          }}
        />

        {isUpdatingPassword && (
          <Input
            id="oldPassword"
            type="password"
            placeholder="Senha atual"
            value={passwords.oldPassword}
            errorMessage={errors.oldPassword}
            showErrorMessage={!!errors.oldPassword}
            variant="light"
            onChange={(value) => {
              setPasswords((prev) => {
                return {
                  ...prev,
                  oldPassword: value,
                };
              });
              setErrors((prev) => {
                return {
                  ...prev,
                  oldPassword: null,
                };
              });
            }}
          />
        )}

        <Input
          id="newPassword"
          type="password"
          placeholder="Nova Senha"
          value={passwords.newPassword}
          errorMessage={errors.newPassword}
          showErrorMessage={!!errors.newPassword}
          variant="light"
          onChange={(value) => {
            setPasswords((prev) => {
              return {
                ...prev,
                newPassword: value,
              };
            });
            setErrors((prev) => {
              return {
                ...prev,
                newPassword: null,
              };
            });
          }}
          icon={
            <Icon
              type="button"
              onClick={() => setIsUpdatingPassword(!isUpdatingPassword)}
            >
              ?
            </Icon>
          }
        />

        {isUpdatingPassword && (
          <Input
            id="confirmPassword"
            type="password"
            placeholder="Confirme sua senha"
            value={passwords.confirmPassword}
            errorMessage={errors.confirmPassword}
            showErrorMessage={!!errors.confirmPassword}
            variant="light"
            onChange={(value) => {
              setPasswords((prev) => {
                return {
                  ...prev,
                  confirmPassword: value,
                };
              });
              setErrors((prev) => {
                return {
                  ...prev,
                  confirmPassword: null,
                };
              });
            }}
          />
        )}

        <Button
          type="submit"
          isLoading={isLoading}
          //disabled={!user.name || !user.lastname}
        >
          Salvar
        </Button>
      </Container>

      <ColumnMFA>
        {user.enable_2fa === "1" ? (
          <>
            <Text>MFA Habilitado</Text>

            {qrcode && (
              <ContentQrCode>
                <BoxQrCode>
                  <QrCodeImg src={qrcode} alt="Google Authenticator QrCode" />
                </BoxQrCode>
              </ContentQrCode>
            )}
          </>
        ) : (
          <Button
            type="button"
            isLoading={isEnablingMFA}
            onClick={handleEnableMFA}
          >
            Habilitar MFA
          </Button>
        )}
      </ColumnMFA>
    </Line>
  );
};

export default ProfilePage;
