import styled from "styled-components";

const Label = styled.label`
  border: 1px dashed white;
  margin: 0;
  color: white;
  outline: none;
  background-color: transparent;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 0.25rem;
  width: 100%;
  cursor: pointer;

  &:hover {
    border: ${props => props.isToAddBorderAnimate ? "none" : "auto"};
  }

  &.add-border-animate {
    background: linear-gradient(90deg, #FDFDFD 50%, transparent 50%), 
                linear-gradient(90deg, #FDFDFD 50%, transparent 50%), 
                linear-gradient(0deg, #FDFDFD 50%, transparent 50%), 
                linear-gradient(0deg, #FDFDFD 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 4px 1.5px, 4px 1.5px, 1.5px 4px, 1.5px 4px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    animation: dash 5s linear infinite;
    margin: 0;
    padding: 1px;
  }

  @keyframes dash {
    to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
  }
`;

const Input = styled.input`
    width: 0;
    height: 0;
    opacity: 0;
`;

export { Label, Input };
