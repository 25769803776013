import styled from 'styled-components';

export const BlocoInformacoes = styled.div`
  max-height: 80px;
  flex: 1;
`;

export const DivMainBloco = styled.div``;

export const BlocoFundo = styled.div`
  background-image: url(${(props) => props.bg});
  height: 220px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  position: relative;
  object-fit: cover;
`;

export const BlocoNome = styled.div`
  background-color: #1c1c1c;
  height: 100px;
  width: 100%;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export const W50 = styled.div`
  width: 50px;
`;
export const FlexEndNome = styled.div`
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex: 1;

  position: relative;
  z-index: 1000;
  
  .btn-outline-secondary,
  .btn-outline-secondary:hover,
  .btn-outline-secondary:active,
  .btn-outline-secondary:focus,
  .btn-outline-secondary:not(:hover):not([aria-expanded="true"]):not([aria-pressed="true"]),
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    border: none;
    background-color: transparent;
    color: #717171;
    box-shadow: none;
  }
`;

export const Logo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
`;

export const InfluencerImg = styled(Logo)`
  border: 1px solid #FFFFFF;
  margin-left: -10px;
  position: relative;
`;

export const NumeroAplicacoes = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  right: 0;
  background-color:rgba(43, 43, 43,.6);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FonteNome = styled.div`
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
  text-overflow: ellipsis !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const BlockInfluencers = styled.div`
  flex: 1;
  display: flex;
  height: 52px;
  justify-content: flex-end;
`;

export const FontePromo = styled.div`
  /* color: #717171; */
  margin-left: 10px;
  margin-right: 15px;
  font-size: 11px;
  height: auto;
  text-overflow: ellipsis !important;
  overflow: hidden;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const colors = {
  "ACTIVE": '#00B9FF',
  "REVIEW": '#925FFA',
  "FINISHED": '#00C268',
  "DRAFT": '#D51E9E',
  "PAUSED": '#FDC760',
}

export const LabelStatus = styled.div`
  box-shadow: 10px 10px 10px -8px rgb(0 0 0 / 75%);
  font-size: 0.66rem;
  border-radius: 7px;
  padding: 0.17rem 0.8rem;
  color: #fff;
  font-weight: 700;
  background-color: ${({ status }) => colors[status]};
  z-index: 0;
`;

export const LabelDataInicio = styled(LabelStatus)`
  background-color: #707070;
  top: 1rem;
  left: calc(1rem + 10px);
`;

export const LineLabels = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  top: 1rem;
  left: 1rem;
`;

export const ChangeStatus = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`;