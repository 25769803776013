import React, { useState, useCallback } from 'react';
import Input from "../../components/input";
import Button from "../../components/button-login";
import logoKuak from "../../assets/images/kuak_logo_branco.svg";
import { resetPassword } from '../../services/auth';
import { Container, Form, Title, Logo, Text, Label, SuccessBox, Small } from './style';

const RecoverPassPage = ({ navigation }) => {
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const submit = useCallback((e, email) => {
        e.preventDefault();
        setIsLoading(true);

        if (email && email !== '') {
            const data = {
              email: email.toLowerCase()
            };

            resetPassword(data)
            .then(({ data }) => {
                if (data.success) {
                    setSuccess(true);
                } else {
                    if (data.status === 'failed') {
                        setError("O email informado não existe em nossa base de dados.");
                    } else {
                        setError("Ocorreu um erro inesperado. Entre em contato com o chat para resolução do problema.");
                    }
                }
            })
            .catch(err => {
                if(err?.response?.data?.error === 'user nor found') {
                    setError("O email informado não existe em nossa base de dados");
                }
                else {
                    setError('Ocorreu um erro inesperado.')
                }
            })
            .finally(() => setIsLoading(false));
        } else {
            setError("Informe um email para resetar sua senha.");
            setIsLoading(false);
        }
    }, []);
    
    return (
        <Container>
            <Logo src={logoKuak} alt="Logo Kuak" />

            <Title>
                Recuperação de Senha
            </Title>

            <Text>
                Esqueceu sua senha? Não se preocupe, nós te ajudamos.
            </Text>

            <Form onSubmit={(e) => submit(e, email)}>
                <Label htmlFor="email">
                    Digite seu e-mail por favor
                </Label>
                <Input 
                    id="email" 
                    type="email"
                    placeholder="E-mail"
                    value={email}
                    errorMessage={error}
                    showErrorMessage={!!error}
                    onChange={value => {
                        setError(null);
                        setEmail(value);
                    }}
                />

                <Button 
                    type="submit" 
                    disabled={!email}
                    isLoading={isLoading}
                >
                    Enviar
                </Button>

            </Form>
            
            {success && (
                <>
                    <SuccessBox>
                        <span>
                            Um link de redefinição foi enviado para o email: {email}.
                        </span>
                        <span>
                            Verifique sua caixa de email.
                        </span>

                    </SuccessBox>
                    <Small>
                        Ir para página de <a href="/login"> Login.</a>
                    </Small>
                </>
            )}
        </Container>
    )
};

export default RecoverPassPage;