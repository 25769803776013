import React from 'react';
import styled from 'styled-components';
import verifiedIcon from '../../assets/images/verified.svg';

const VerifiedIcon = ({ size }) => {
    return (
        <Icon size={size} src={verifiedIcon} />
    )
};

export default VerifiedIcon;

const Icon = styled.img`
    width: ${({ size }) => size ?? '20px'};
    height: ${({ size }) => size ?? '20px'};
    object-fit: cover;
`;