import React from "react";
import { Input, Label } from "./styles";
import { v4 as uuid } from "uuid";

export const SingleUpload = ({ 
  handleToggleMouseDragArea, 
  isMouseOnDragArea, 
  onDropFileLabel,
  onFileUploaded,
  filesUploaded,
  handleChangeInput,
  children,
  isToAddBorderAnimate,
}) => {
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (filesUploaded.length <= 0) return;
    const [file] = filesUploaded;

    onFileUploaded(file);
  }, [filesUploaded]);

  const componentId = React.useMemo(() => uuid(), []);

  return (
    <>
      <Label
        isToAddBorderAnimate={isToAddBorderAnimate}
        htmlFor={componentId}
        onMouseEnter={handleToggleMouseDragArea}
        onMouseLeave={handleToggleMouseDragArea}
        className={isMouseOnDragArea && isToAddBorderAnimate ? 'add-border-animate' : ''}
        data-testid="single-upload-label"
        onDrop={onDropFileLabel}
      >
        {children}
      </Label>
      <Input
        ref={inputRef}
        data-testid="input-single-upload"
        type="file" 
        id={componentId} 
        name="single-upload"
        onChange={handleChangeInput}
      />
    </>
  );
}
