import React, { useCallback } from 'react';
import SuccessIcon from "../../assets/images/success_icon.png";
import ErrorIcon from '../../assets/images/error_icon.png';
import { Container } from './styles';

export function Toast({ toastType, message, removeToast }) {
  const [isAnimationOutOn, setIsAnimationOutOn] = React.useState(false);

  const types = {
    SUCCESS: {
      title: 'Sucesso',
      background: '#4bb543',
      icon: SuccessIcon,
    },
    ERROR: {
      title: 'Erro',
      background: '#e32636',
      icon: ErrorIcon,
    },
  };

  function toggleAnimationOutOn() {
    setIsAnimationOutOn(prev => !prev);
  }

  const showOffToast = useCallback(() => {
    if (!isAnimationOutOn) return;

    removeToast();
    toggleAnimationOutOn();
  }, [isAnimationOutOn, removeToast]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      toggleAnimationOutOn();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container
      background={types[toastType].background}
      className={isAnimationOutOn ? 'animation-out' : ''}
      onAnimationEnd={showOffToast}
      data-testid="toast-container"
    >
      <img src={types[toastType].icon} alt={`Toast icon ${types[toastType].title}`} />
      <figcaption>
        <p>{types[toastType].title}</p>
        <p>{message}</p>
      </figcaption>
    </Container>
  );
}
