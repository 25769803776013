import axios from 'axios';
import api from './index';
import { getItem, setItem } from './localStorage';
import * as constants from '../constants/api';

export const isAuthenticated = () => {
  const properties = JSON.parse(getItem("userProperties"));

  return getItem(constants.TOKEN_KEY) !== null && properties.isAuthenticated;
};

export const setUserProperties = (data) => {
  setItem("userProperties", JSON.stringify(data));
};
  
export const hasRoles = (allowed) => {
  if (!allowed) {
    return true;
  } else {
    var properties = getItem("userProperties");
    if (properties && properties !== "undefined") {
      var roles = JSON.parse(properties)?.roles;
      if (roles) {
        var hasRoles = roles?.some((role) => allowed.includes(role.authority));
        return hasRoles;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};
  
export const getUserProperties = async () => {
  var unparsed = getItem("userProperties");

  if (unparsed && unparsed !== undefined && unparsed !== "undefined") {
    var properties = JSON.parse(unparsed);
    if (properties) {
      return properties;
    } else {
    const response2 = await api.get("/users/show/profile").then((res) => res);

    setUserProperties(response2.data);
        return response2.data;
    }
  }
};

export const login = (data) => {
  return api.post("/auth/login", data);
}

export const logout = () => {
  const token = getItem(constants.TOKEN_KEY);

  axios(`${constants.API_URL}/auth/logout`, {
    method: 'post',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  })
  
  localStorage.clear();
};

export const resetPassword = (data) => {
  return api.post('/users/resetLink', data)
}

export const updateNewPassword = (token, data) => {
  return api.post('/users/updateNewPassword', data, { headers: {
    Authorization: `Bearer ${token}`
  }})
}

export const enableMFA = () => {
  return api.post('/auth/googlemfa');
}

/**
 * 
 * @param {*} data 
 * data: {
    email: string;
    code: string;
  }
 */
export const verifyCodeMFA = (data) => {
  return api.post('/auth/verify', data);
}

export const getUserQrCode = () => {
  return api.get('/auth/mfa');
}