import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { TrashIcon, TimesIcon, EyeIcon } from "react-line-awesome";
import Toggle from "../../components/toggle";
import Input from "../../components/input";
import Select from "../../components/dropdown";
import ModalFullscreen from "../../components/modal-fullscreen";
import { Checkbox } from "../../components/checkbox-radio";
import VerifiedIcon from "../../components/verified-icon";
import ButtonSubmit from "../../components/button-login";
import Label from "../../components/label";
import Title from "../../components/form-subtitle";
import BounceSpinner from "../../components/bounce-spinner";
import AsyncSelect from "../../components/async-select";
import alertNotify from "../../components/alert-notify";
import { getBrandById, updateBrand, deleteBrand } from "../../services/brands";
import { searchCities } from "../../services/cities";
import { getBrandCategories } from "../../services/categories";
import inputMasks from "../../utils/masks";
import validators from "../../utils/validators";
import ufs from "../../constants/ufs";
import { uploadFile } from "../../components/uploader/helpers/uploadToApi";
import { Uploader } from "../../components/uploader";

import {
  Form,
  ButtonDelete,
  Button,
  Line,
  TextVerified,
  Column,
  Relative,
  ProfilePicture,
  ButtonRemoveImg,
  Group,
  LineNoWrap,
  ColumnFlex,
  ButtonConfirm,
  ButtonCancel,
  FlexEnd,
  BannerContainer,
  Banner,
  TransparentButton,
  BannerImg,
} from "./style";

const BrandPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [brand, setBrand] = useState(null);
  const [actualBanner, setActualBanner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalBanner, setShowModalBanner] = useState(false);

  const [plan, setPlan] = useState(4);
  const [enabled, setEnabled] = useState(false);
  const [accountLocked, setAccountLocked] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEmphasis, setIsEmphasis] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({
    cpnj: "",
    email: "",
    confirmPassword: "",
  });

  const optionsPlan = useMemo(() => {
    return [
      {
        id: 4,
        value: 4,
        label: "Básico",
      },
      {
        id: 5,
        value: 5,
        label: "Full",
      },
    ];
  }, []);

  const optionsStatus = useMemo(() => {
    return [
      {
        id: 1,
        value: true,
        label: "Ativo",
      },
      {
        id: 0,
        value: false,
        label: "Inativo",
      },
    ];
  }, []);

  const optionsAccountLocked = useMemo(() => {
    return [
      {
        id: 1,
        value: false,
        label: "Desbloqueado",
      },
      {
        id: 0,
        value: true,
        label: "Bloqueado",
      },
    ];
  }, []);

  const optionsUF = useMemo(() => {
    return ufs.map((uf) => {
      return {
        key: uf.id,
        value: uf.value,
        label: uf.name,
      };
    });
  }, []);

  const loadCities = useCallback(async (inputCity, callback) => {
    await searchCities(inputCity).then((response) => {
      const options = response.data.map((city) => {
        return {
          value: city.id,
          label: `${city.name}, ${city.state.code}`,
          uf: city.state.code,
        };
      });

      callback(options);
    });
  }, []);

  const handleUploadProfilePic = useCallback((file) => {
    if (!file) {
      return;
    }

    uploadFile(file).then((resp) => {
      const data = {
        cdnUrl: resp.mediaUrl,
        uuid: resp.uuid,
        name: file.name,
        originalUrl: resp.mediaUrl,
      };

      setBrand((prev) => {
        return {
          ...prev,
          avatarInfo: data,
        };
      });
    });
  }, []);

  const handleUploadBanner = useCallback((file) => {
    if (!file) {
      return;
    }
    uploadFile(file).then((resp) => {
      const data = {
        cdnUrl: resp.mediaUrl,
        uuid: resp.uuid,
        name: file.name,
        originalUrl: resp.mediaUrl,
      };
      setBrand((prev) => {
        return {
          ...prev,
          banner: data,
        };
      });
    });
  }, []);

  const handleBrand = useCallback((value, field) => {
    if (field === "email" || field === "cnpj" || field === "confirmPassword") {
      setErrors((prev) => {
        return {
          ...prev,
          [field]: "",
        };
      });
    }

    setBrand((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }, []);

  const removeBrand = useCallback(async (brandId) => {
    if (!brandId) return;

    setIsLoadingDelete(true);

    await deleteBrand(brandId)
      .then((response) => {
        setIsLoadingDelete(false);
        setShowModalConfirm(false);
        alertNotify("Marca excluída com sucesso!", null, "success");

        navigate("/brands");
      })
      .catch(() => {
        setIsLoadingDelete(false);
        alertNotify("Ocorreu um erro ao excluir essa marca.", null, "danger");
      });
  }, []);

  const submit = useCallback(
    async (
      e,
      brand,
      plan,
      enabled,
      accountLocked,
      isEmphasis,
      isNew,
      isVerified,
      actualBanner
    ) => {
      e.preventDefault();

      if (!brand?.cnpj || !brand?.email) {
        setErrors((prev) => {
          return {
            cnpj: !brand?.cnpj ? "CNPJ obrigatório" : "",
            email: !brand?.email ? "E-mail obrigatório" : "",
          };
        });
        return;
      }

      const isValidCnpj = validators.isValidCnpj(brand.cnpj);

      if (!isValidCnpj) {
        setErrors((prev) => {
          return {
            ...prev,
            cnpj: "CNPJ inválido",
          };
        });

        return;
      }

      if (brand.password) {
        if (brand?.password !== brand?.confirmPassword) {
          setErrors((prev) => {
            return {
              ...prev,
              confirmPassword: "As senhas não coincidem",
            };
          });

          return;
        }
      }

      let avatarInfo = brand.avatarInfo;

      if (brand.avatarInfo && !brand.avatarInfo?.version) {
        avatarInfo = {
          ...brand.avatarInfo,
          version: 0,
        };
      }

      let data = {
        id: brand.id,
        avatarInfo: avatarInfo,
        accountLocked: accountLocked,
        city_id: brand.city?.value,
        segment: brand.category,
        instagram: brand.instagram,
        name: brand.name,
        lastname: brand.lastname,
        company: brand.company,
        doc: brand.cnpj,
        docType: "CNPJ",
        site: brand.site,
        type: brand.type,
        phone: brand.phone,
        email: brand.email,
        planId: plan.toString(),
        enabled: enabled,
        isEmphasis: isEmphasis,
        isNew: isNew,
        isVerified: isVerified,
      };

      if (brand?.password && brand?.confirmPassword) {
        data.password = brand.password;
        data.confirmPassword = brand.confirmPassword;
      }

      if (!brand?.welcome && enabled) {
        data.welcome = true;
      }

      if (brand.banner && brand.banner.uuid !== actualBanner?.uuid) {
        data.banner = brand.banner;
      }

      await updateBrand(data)
        .then((response) => {
          alertNotify("Marca atualizada com sucesso!", null, "success");
          navigate("/brands");
        })
        .catch((err) => {
          alertNotify("Ocorreu um erro ao atualizar a marca.", null, "danger");
        });
    },
    []
  );

  useEffect(() => {
    async function getBrand(brandId) {
      setIsLoading(true);

      await getBrandById(brandId)
        .then(({ data }) => {
          const {
            id,
            avatarInfo,
            banner,
            instagram,
            name,
            lastname,
            company,
            doc,
            site,
            type,
            phone,
            email,
            plan,
            enabled,
            accountLocked,
            isNew,
            isEmphasis,
            isVerified,
            welcome,
            segments,
          } = data;

          setBrand({
            id: id,
            avatarInfo,
            banner,
            instagram,
            city: data.city
              ? {
                  value: data.city.id,
                  label: `${data.city.name}, ${data.city?.state?.code}`,
                  uf: data.city?.state?.code,
                }
              : null,
            name,
            lastname,
            company,
            cnpj: doc,
            site,
            type: type.toUpperCase(),
            phone,
            email,
            welcome,
            category: segments?.length > 0 ? segments[0].id : "",
          });

          setActualBanner(banner);
          setPlan(plan?.id.toString() === "5" ? 5 : 4);
          setEnabled(Boolean(enabled && welcome));
          setAccountLocked(accountLocked);
          setIsNew(isNew);
          setIsEmphasis(isEmphasis);
          setIsVerified(isVerified);
        })
        .catch((err) => {
          console.log(err);
          alertNotify(
            "Ocorreu um erro ao recuperar os dados da marca.",
            null,
            "danger"
          );
        })
        .finally(() => setIsLoading(false));
    }

    if (params.id) {
      getBrand(params.id);
    }
  }, [params]);

  useEffect(() => {
    async function getCategories() {
      await getBrandCategories()
        .then(({ data }) => {
          setCategories(
            data.segments.map((category) => {
              return {
                key: category.id,
                value: category.id,
                label: category.name,
              };
            })
          );
        })
        .catch((err) => {
          alertNotify(
            "Ocorreu um erro ao recuperar a lista de categorias disponíveis.",
            null,
            "danger"
          );
        });
    }

    getCategories();
  }, []);

  useEffect(() => {
    if (brand?.city) {
      setBrand((prev) => {
        return {
          ...prev,
          uf: prev.city.uf,
        };
      });
    }
  }, [optionsUF, brand?.city]);

  return (
    <ModalFullscreen
      title="Marca"
      goBackHref="/brands"
      headerOptions={
        <>
          <ButtonDelete onClick={() => setShowModalConfirm(true)}>
            <TrashIcon />
          </ButtonDelete>

          <Button to={`/brand/${params.id}/campaigns`}>Campanhas</Button>
        </>
      }
    >
      {isLoading ? (
        <BounceSpinner />
      ) : (
        <Form
          onSubmit={(e) =>
            submit(
              e,
              brand,
              plan,
              enabled,
              accountLocked,
              isEmphasis,
              isNew,
              isVerified,
              actualBanner
            )
          }
        >
          <Line>
            <Column>
              {!brand?.avatarInfo ? (
                <>
                  <Uploader
                    isSingleUpload
                    onlyImages
                    onFileUploaded={(file) => handleUploadProfilePic(file.file)}
                    //TODO - clearable
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                        width: "100%",
                        padding: "0 20px",
                      }}
                    >
                      Escolha o Logo
                    </div>
                  </Uploader>
                </>
              ) : (
                <Relative>
                  <ProfilePicture src={brand?.avatarInfo.cdnUrl} />

                  <ButtonRemoveImg
                    type="button"
                    onClick={() => setBrand({ ...brand, avatarInfo: null })}
                  >
                    <TimesIcon />
                  </ButtonRemoveImg>
                </Relative>
              )}
            </Column>

            <Column>
              <Line>
                <Toggle
                  id="tg-plan"
                  options={optionsPlan}
                  onChange={(value) => setPlan(value)}
                  value={plan}
                />

                <Toggle
                  id="tg-enabled"
                  options={optionsStatus}
                  onChange={(value) => setEnabled(value)}
                  value={enabled}
                  color="var(--active-status-color)"
                />

                <Toggle
                  id="tg-accountLocked"
                  options={optionsAccountLocked}
                  onChange={(value) => setAccountLocked(value)}
                  value={accountLocked}
                  color="var(--active-status-color)"
                />
              </Line>

              <Line>
                <Checkbox
                  id="check-is-new"
                  name="check-is-new"
                  label="Marca Recente"
                  checked={isNew}
                  onChange={({ target }) => setIsNew(target.checked)}
                />

                <Checkbox
                  id="check-is-emphasis"
                  name="check-is-emphasis"
                  label="Destacar Marca na Categoria"
                  checked={isEmphasis}
                  onChange={({ target }) => setIsEmphasis(target.checked)}
                />

                <Checkbox
                  id="check-is-verified"
                  name="check-is-verified"
                  label={
                    <TextVerified>
                      <VerifiedIcon /> Marca Registrada{" "}
                    </TextVerified>
                  }
                  checked={isVerified}
                  onChange={({ target }) => setIsVerified(target.checked)}
                />
              </Line>
            </Column>
          </Line>

          <LineNoWrap>
            <ColumnFlex>
              <Group>
                <Label htmlFor="instagram">@ do Instagram</Label>

                <Input
                  id="instagram"
                  type="text"
                  variant="dark"
                  placeholder="Instagram"
                  leftText="@"
                  value={brand?.instagram}
                  onChange={(value) => handleBrand(value, "instagram")}
                />
              </Group>
            </ColumnFlex>
            <ColumnFlex>
              <Group>
                <Label htmlFor="bannerUploader">Banner</Label>
                <BannerContainer>
                  {!brand?.banner ? (
                    <>
                      <Uploader
                        isSingleUpload
                        onlyImages
                        onFileUploaded={(file) => handleUploadBanner(file.file)}
                        //TODO - clearable
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                          }}
                        >
                          Adicionar Banner
                        </div>
                      </Uploader>
                    </>
                  ) : (
                    <Banner>
                      <span>{brand.banner.name}</span>
                      <TransparentButton
                        type="button"
                        onClick={() => setShowModalBanner(true)}
                      >
                        <EyeIcon />
                      </TransparentButton>

                      <TransparentButton
                        type="button"
                        onClick={() => setBrand({ ...brand, banner: null })}
                      >
                        <TimesIcon />
                      </TransparentButton>
                    </Banner>
                  )}
                </BannerContainer>
              </Group>
            </ColumnFlex>
          </LineNoWrap>

          <LineNoWrap>
            <ColumnFlex>
              <Title>Dados do Contato</Title>

              <Group>
                <Input
                  id="name"
                  type="text"
                  variant="dark"
                  placeholder="Nome"
                  value={brand?.name}
                  onChange={(value) => handleBrand(value, "name")}
                />
                <Input
                  id="lastname"
                  type="text"
                  variant="dark"
                  placeholder="Sobrenome"
                  value={brand?.lastname}
                  onChange={(value) => handleBrand(value, "lastname")}
                />
              </Group>

              <Title>Dados da Empresa</Title>

              <Group>
                <Input
                  id="company"
                  type="text"
                  variant="dark"
                  placeholder="Empresa"
                  value={brand?.company}
                  onChange={(value) => handleBrand(value, "company")}
                />
              </Group>
              <Group>
                <Input
                  id="cnpj"
                  type="text"
                  variant="dark"
                  mask={() => inputMasks.cnpj()}
                  placeholder="CNPJ"
                  value={brand?.cnpj}
                  errorMessage={errors.cnpj}
                  showErrorMessage={!!errors.cnpj}
                  onChange={(value) => handleBrand(value, "cnpj")}
                />
              </Group>
              <Group>
                <AsyncSelect
                  id="city"
                  loadOptions={loadCities}
                  value={brand?.city}
                  noOptionsMessage="Essa cidade não existe"
                  placeholder="Cidade"
                  onChange={(value) => handleBrand(value, "city")}
                />
              </Group>
              <Group>
                <Select
                  id="uf"
                  type="text"
                  variant="dark"
                  placeholder="Estado"
                  value={brand?.uf}
                  onChange={(value) => handleBrand(value, "uf")}
                  options={optionsUF}
                />
                <Input
                  id="phone"
                  type="text"
                  variant="dark"
                  mask={() => inputMasks.phone()}
                  placeholder="Telefone"
                  value={brand?.phone}
                  onChange={(value) => handleBrand(value, "phone")}
                />
              </Group>
              <Group>
                <Input
                  id="site"
                  type="text"
                  variant="dark"
                  placeholder="Site"
                  value={brand?.site}
                  onChange={(value) => handleBrand(value, "site")}
                />
                <Select
                  id="type"
                  type="text"
                  variant="dark"
                  placeholder="Eu sou uma"
                  value={brand?.type}
                  onChange={(value) => handleBrand(value, "type")}
                  options={[
                    { key: "AGENCY", value: "AGENCY", label: "Agência" },
                    { key: "BRAND", value: "BRAND", label: "Marca" },
                  ]}
                />
              </Group>
            </ColumnFlex>

            <ColumnFlex>
              <Title>Categoria</Title>

              <Group>
                <Select
                  id="category"
                  type="text"
                  variant="dark"
                  placeholder="Categoria"
                  value={brand?.category}
                  onChange={(value) => handleBrand(value, "category")}
                  options={categories}
                />
              </Group>

              <Title>Dados para Acesso</Title>

              <Group>
                <Input
                  id="email"
                  type="email"
                  variant="light"
                  placeholder="E-mail"
                  value={brand?.email}
                  errorMessage={errors.email}
                  showErrorMessage={!!errors.email}
                  onChange={(value) => handleBrand(value, "email")}
                />
              </Group>
              <Group>
                <Input
                  id="password"
                  type="password"
                  variant="light"
                  placeholder="Senha"
                  value={brand?.password}
                  onChange={(value) => handleBrand(value, "password")}
                />
                <Input
                  id="confirmPassword"
                  type="password"
                  variant="light"
                  placeholder="Confirme a senha"
                  value={brand?.confirmPassword}
                  errorMessage={errors.confirmPassword}
                  showErrorMessage={!!errors.confirmPassword}
                  onChange={(value) => handleBrand(value, "confirmPassword")}
                />
              </Group>
            </ColumnFlex>
          </LineNoWrap>

          <ButtonSubmit
            type="submit"
            //disabled={!user.firstname || !user.lastname}
          >
            Salvar
          </ButtonSubmit>
        </Form>
      )}

      <Modal show={showModalConfirm} centered>
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
          onHide={() => setShowModalConfirm(false)}
        />
        <Modal.Body>
          <Column>
            <p>Tem certeza de que deseja excluir essa marca?</p>
            <small>Essa ação não poderá ser desfeita.</small>

            <FlexEnd>
              <ButtonCancel
                type="button"
                disabled={isLoadingDelete}
                onClick={() => setShowModalConfirm(false)}
              >
                Cancelar
              </ButtonCancel>

              <ButtonConfirm
                type="button"
                disabled={isLoadingDelete}
                onClick={() => removeBrand(brand?.id)}
              >
                {isLoadingDelete && (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    size="sm"
                    variant="light"
                  />
                )}
                Excluir
              </ButtonConfirm>
            </FlexEnd>
          </Column>
        </Modal.Body>
      </Modal>

      <Modal show={showModalBanner} centered size="lg">
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
          onHide={() => setShowModalBanner(false)}
        />
        <Modal.Body>
          <BannerImg src={brand?.banner?.cdnUrl} alt="Banner da marca" />
        </Modal.Body>
      </Modal>
    </ModalFullscreen>
  );
};

export default BrandPage;
