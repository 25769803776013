import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--black-color);
`;

export const Form = styled.form`
    gap: 10px;
    display: flex;
    flex-direction: column;
`;

export const Logo = styled.img`
    width: 180px;
    object-fit: cover;
    margin-bottom: 25px;

    @media(max-width: 768px) {
        margin-bottom: 10px; 
    }
`;

export const Title = styled.h1`
    font-weight: 500;
    font-size: 30px;
    font-family: Quicksand;
    letter-spacing: -0.9px;
    margin: 50px 0 0;
    color: #fff;
`;

export const Text = styled.h2`
    font-weight: 500;
    font-size: 20px;
    font-family: Quicksand;
    letter-spacing: -0.6px;
    margin: 10px 0 20px;
    color: #fff;
`;

export const Label = styled.label`
    font-weight: 500;
    font-size: 20px;
    font-family: Quicksand;
    letter-spacing: -0.6px;
    margin-bottom: 10px;
    color: #fff;
    align-self: center;
`;

export const SuccessBox = styled.div`
    font-size: 14px;
    font-family: Quicksand;
    letter-spacing: -0.6px;
    color: #fff;
    padding: 16px;
    border-radius: 8px;
    max-width: 350px;
    background-color: var(--active-status-color);
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

export const Small = styled.small`
    color: rgba(255, 255, 255, .7);
    text-align: center;
    margin-top: 10px;

    a {
        color: var(--primary-color);

        &:hover {
            opacity: .7;
        }
    }
`;