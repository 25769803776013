import React from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
} from 'recharts';
import formatters from '../../utils/formatters';
import { MyTooltip, ResponsiveContainer } from './style';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <MyTooltip>
            {formatters.moneyBR(payload[0].value)}
        </MyTooltip>
      );
    }
  
    return null;
  };
  

const DashboardGraph = ({ data }) => {
    return (
        <ResponsiveContainer>
            <AreaChart
                data={data}
                margin={{
                    top: 20,
                    right: 0,
                    left: 0,
                    bottom: 0
                }}
            >
                <defs>
                    <linearGradient id="colorByDay" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#AC4BFF" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#AC4BFF" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="0" />
                <XAxis 
                    dataKey="data" 
                    tick={{ fill: '#fff', fontSize: '12px' }}
                    tickFormatter={formatters.date}
                    />
                <YAxis
                    domain={[0, 'dataMax + 1000']}
                    tick={{ fill: '#fff', fontSize: '12px' }} 
                    tickFormatter={formatters.formatNumberShort}
                />
                <Tooltip content={<CustomTooltip />} />
                <Area 
                    dataKey="total" 
                    stroke="#AC4BFF" 
                    fillOpacity={1} 
                    fill="url(#colorByDay)"
                    dot={{ stroke: '#AC4AFC', strokeWidth: 2, fill: '#fff', r: 5 }}
                />
            </AreaChart>
        </ResponsiveContainer>
    )
};

export default DashboardGraph;