import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ModalFullscreen from '../../components/modal-fullscreen';
import StatusBar from '../../components/status-bar';
import BlockCampaign from '../../components/campaign-block';
import alertNotify from '../../components/alert-notify';
import { getCampaignsByBrandId } from '../../services/campaigns';
import { Container, Grid } from './style';

const CampaignsPage = () => {
    const params = useParams();
    
    const [activeStatus, setActiveStatus] = useState('ALL');
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        async function getCampaigns(brandId) {
            await getCampaignsByBrandId(brandId)
            .then(({ data }) => {
                if(data?.length > 0) {
                    setCampaigns(data);
                }
            })
            .catch(error => {
                alertNotify('Ocorreu um erro ao recuperar as campanhas da marca', null, 'danger')
            });
        }

        if(params.id) {
            getCampaigns(params.id)
        }
    }, [params]);

    const changeStatus = useCallback((campaignId, status) => {
        setCampaigns(prev => {
            return prev.map(campaign => campaign.id === campaignId ? { ...campaign, status: status } : campaign);
        });
    }, []);

    return (
        <ModalFullscreen
            title="Marca"
            goBackHref={`/brand/${params.id}`}
        >
            <Container>
                <StatusBar  activeStatus={activeStatus} handleActiveStatus={value => setActiveStatus(value)} />

                <Grid>
                    {campaigns
                      .filter(
                        (campaign) =>
                          campaign.status === activeStatus ||
                          activeStatus === "ALL" ||
                          (campaign.status === "ACTIVATE" &&
                            activeStatus === "ACTIVE")
                      )
                      .map((campaign, index) => (
                        <BlockCampaign
                            key={index}
                            banner={campaign.heroImage?.cdnUrl}
                            status={campaign.status}
                            logoEmpresa={campaign.profile ? campaign.profile.logoInfo.cdnUrl : ""}
                            nomeCampanha={campaign.name}
                            nomeEmpresa={campaign.brand.company}
                            dataInicio={campaign.startDate}
                            publishers={campaign.publishers}
                            numAplicacoes={campaign.applications}
                            campaignId={campaign.id}
                            changeStatus={changeStatus}
                        />
                      ))
                    }
                </Grid>
            </Container>

        </ModalFullscreen>
    )
};

export default CampaignsPage;