import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { SortableItem } from "./Item";

export const SortableList = SortableContainer(({ items, ...props }) => {
  return (
    <ul>
      {items.map(({ preview, cdnUrl, file, id }, index) => (
        <SortableItem
          lockToContainerEdge={true}
          key={`item-${index}`}
          index={index}
          value={cdnUrl || preview}
          file={file}
          id={id}
          {...props}
        />
      ))}
    </ul>
  );
});
