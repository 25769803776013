import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Toggle from '../../components/toggle';
import Input from '../../components/input';
import Select from '../../components/dropdown';
import ModalFullscreen from '../../components/modal-fullscreen';
import { Checkbox } from '../../components/checkbox-radio';
import ButtonSubmit from '../../components/button-login';
import Label from '../../components/label';
import Title from '../../components/form-subtitle';
import BounceSpinner from '../../components/bounce-spinner';
import AsyncSelect from '../../components/async-select';
import alertNotify from '../../components/alert-notify';
import { getInfluencerById, updateInfluencer } from '../../services/influencers';
import { searchCities } from '../../services/cities';
import inputMasks from '../../utils/masks';
import validators from '../../utils/validators';
import { 
    Form,
    Line, 
    Column, 
    ProfilePicture, 
    Group,
    LineNoWrap,
    ColumnFlex
} from './style';

const InfluencerPage = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [influencer, setInfluencer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [plan, setPlan] = useState(4);
    const [enabled, setEnabled] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [errors, setErrors] = useState({
        cpf: '',
        email: '',
        instagram: '',
        confirmPassword: '',
    });

    const optionsPlan = useMemo(() => {
        return [
            {
                id: 4,
                value: 4,
                label: 'Básico',
            },
            {
                id: 5,
                value: 5,
                label: 'Full',
            },
        ]
    }, []);

    const optionsStatus = useMemo(() => {
        return [
            {
                id: 1,
                value: true,
                label: 'Ativo',
            },
            {
                id: 0,
                value: false,
                label: 'Inativo',
            },
        ]
    }, []);


    const loadCities = useCallback(async (inputCity, callback) => {
        await searchCities(inputCity)
        .then((response) => {
          const options = response.data.map(city => { 
            return { value: city.id, label: `${city.name}, ${city.state.code}`, uf: city.state.code } 
          })
    
          callback(options);
        });
    }, []);

    const handleInfluencer = useCallback((value, field) => {
        if(field === 'email' || field === 'cnpj' || field === 'instagram' || field === 'confirmPassword') {
            setErrors(prev => {
                return {
                    ...prev,
                    [field]: ''
                }
            });
        }

        setInfluencer(prev => {
            return {
                ...prev,
                [field]: value
            }
        });
    }, []);

    const submit = useCallback(async (e, influencer, plan, enabled, isNew) => {
        e.preventDefault();

        if(!influencer?.cpf || !influencer?.email || !influencer?.instagram) {
            setErrors(prev => {
                return {
                    cpf: !influencer?.cpf ? 'CPF obrigatório' : '',
                    email: !influencer?.email ? 'E-mail obrigatório' : '',
                    instagram: !influencer?.instagram ? 'Instagram obrigatório' : '',
                }
            })
            return;
        }

        const isValidCpf = validators.isValidCpf(influencer.cpf);

        if(!isValidCpf) {
            setErrors(prev => {
                return {
                    ...prev,
                    cpf: 'CPF inválido',
                }
            });

            return;
        }

        if(influencer.password) {
            if(influencer?.password !== influencer?.confirmPassword) {
                setErrors(prev => {
                    return {
                        ...prev,
                        confirmPassword: 'As senhas não coincidem',
                    }
                });
    
                return;
            }
        }

        let data = {
            id: influencer.id,
            avatarInfo: influencer.avatarInfo,
            city_id: influencer.city?.value,
            doc: influencer.cpf,
            docType: 'CPF',
            email: influencer.email,
            enabled: enabled,
            gender: influencer.gender,
            isNew: isNew,
            lastname: influencer.lastname,
            name: influencer.name,
            phone: influencer.phone,
            planId: plan,
            usernameKard: influencer.instagram,
        }

        if(influencer?.password && influencer?.confirmPassword) {
            data.password = influencer.password;
            data.confirmPassword = influencer.confirmPassword;
        }

        await updateInfluencer(data)
        .then(response => {
            alertNotify('Influencer atualizado com sucesso!', null, 'success');
            navigate('/influencers');
        })
        .catch(err => {
            alertNotify('Ocorreu um erro ao atualizar o influencer.', null, 'danger');
        });
    }, []);

    useEffect(() => {
        async function getInfluencer(influencerId) {
            setIsLoading(true);

            await getInfluencerById(influencerId)
            .then(({ data }) => {
                if(data.success) {
                    setInfluencer({
                        id: data.user.id,
                        city: data.user.city 
                            ? { value: data.user.city.id, label: `${data.user.city.name}, ${data.user.city.state.code}`, uf: data.user.city.state.code } 
                            : null,
                        avatarInfo: data.user.avatarInfo,
                        instagram: data.user.usernameKard,
                        email: data.user.email,
                        name: data.user.name,
                        lastname: data.user.lastname,
                        gender: data.user.gender,
                        phone: data.user.phone,
                        cpf: data.user?.doc,
                    });
                    setIsNew(data.user.isNew);
                    setPlan(data.user?.plan?.id.toString() === '5' ? 5 : 4);
                    setEnabled(data.user.enabled);
                }
                else {
                    alertNotify('Ocorreu um erro ao recuperar os dados do influencer.', null, 'danger');
                }
            })
            .catch(err => {
                alertNotify('Ocorreu um erro ao recuperar os dados do influencer.', null, 'danger');
            })
            .finally(() => setIsLoading(false));
        }

        if(params.id) {
            getInfluencer(params.id)
        }
    }, [params]);

    return (
        <ModalFullscreen
            title="Influencer"
            goBackHref="/influencers"
        >
            {isLoading ? (
                <BounceSpinner />
            ) : (
                <Form onSubmit={(e) => submit(e, influencer, plan, enabled, isNew)}>
                    <Line>
                        <Column>
                            <ProfilePicture alt="Foto de perfil do influencer" src={influencer?.avatarInfo?.cdnUrl} />
                        </Column>

                        <Column>
                            <Line>
                                <Toggle
                                    id="tg-plan"
                                    options={optionsPlan}
                                    onChange={value => setPlan(value)}
                                    value={plan}
                                />

                                <Toggle
                                    id="tg-enabled"
                                    options={optionsStatus}
                                    onChange={value => setEnabled(value)}
                                    value={enabled}
                                    color="var(--active-status-color)"
                                />
                            </Line>

                            <Line>
                                <Checkbox 
                                    id="check-is-new"
                                    name="check-is-new"
                                    label="Influencer Recente"
                                    checked={isNew}
                                    onChange={({ target }) => setIsNew(target.checked)}
                                />
                            </Line>
                        </Column>
                    
                    </Line>

                    <LineNoWrap>
                        <ColumnFlex>
                            <Group>
                                <Label htmlFor="instagram">
                                    @ do Instagram
                                </Label>

                                <Input 
                                    id="instagram" 
                                    type="text"
                                    variant="dark"
                                    placeholder="Instagram"
                                    leftText="@"
                                    value={influencer?.instagram}
                                    errorMessage={errors.instagram}
                                    showErrorMessage={!!errors.instagram}
                                    onChange={value => handleInfluencer(value, 'instagram')}
                                />
                            </Group>
                        </ColumnFlex>
                        <ColumnFlex />
                    </LineNoWrap>

                    <LineNoWrap>

                        <ColumnFlex>
                            <Title>
                                Dados para Acesso
                            </Title>

                            <Group>
                                <Input 
                                    id="email" 
                                    type="email"
                                    variant="light"
                                    placeholder="E-mail"
                                    value={influencer?.email}
                                    errorMessage={errors.email}
                                    showErrorMessage={!!errors.email}
                                    onChange={value => handleInfluencer(value, 'email')}
                                />
                            </Group>
                            <Group>
                                <Input 
                                    id="password" 
                                    type="password"
                                    variant="light"
                                    placeholder="Senha"
                                    value={influencer?.password}
                                    onChange={value => handleInfluencer(value, 'password')}
                                />
                                <Input 
                                    id="confirmPassword" 
                                    type="password"
                                    variant="light"
                                    placeholder="Confirme a senha"
                                    value={influencer?.confirmPassword}
                                    errorMessage={errors.confirmPassword}
                                    showErrorMessage={!!errors.confirmPassword}
                                    onChange={value => handleInfluencer(value, 'confirmPassword')}
                                />
                            </Group>
                        </ColumnFlex>

                        <ColumnFlex>
                            <Title>
                                Dados do Influencer
                            </Title>

                            <Group>
                                <Input 
                                    id="name" 
                                    type="text"
                                    variant="dark"
                                    placeholder="Nome"
                                    value={influencer?.name}
                                    onChange={value => handleInfluencer(value, 'name')}
                                />
                                <Input 
                                    id="lastname" 
                                    type="text"
                                    variant="dark"
                                    placeholder="Sobrenome"
                                    value={influencer?.lastname}
                                    onChange={value => handleInfluencer(value, 'lastname')}
                                />
                            </Group>

                            <Group>
                                <AsyncSelect
                                    id="city"
                                    loadOptions={loadCities}
                                    value={influencer?.city}
                                    noOptionsMessage="Essa cidade não existe"
                                    placeholder="Cidade"
                                    onChange={value => handleInfluencer(value, 'city')}
                                />

                                <Select 
                                    id="gender" 
                                    type="text"
                                    variant="dark"
                                    placeholder="Gênero"
                                    value={influencer?.gender}
                                    onChange={value => handleInfluencer(value, 'gender')}
                                    options={[
                                        { key: 'FEMALE', value: 'FEMALE', label: 'Feminino' },
                                        { key: 'MALE', value: 'MALE', label: 'Masculino' },
                                    ]}
                                />
                            </Group>

                            <Group>
                                <Input 
                                    id="phone" 
                                    variant="dark"
                                    placeholder="Telefone"
                                    value={influencer?.phone}
                                    mask={() => inputMasks.phone()}
                                    onChange={value => handleInfluencer(value, 'phone')}
                                />
                                <Input 
                                    id="cpf" 
                                    variant="dark"
                                    placeholder="CPF"
                                    value={influencer?.cpf}
                                    errorMessage={errors.cpf}
                                    showErrorMessage={!!errors.cpf}
                                    mask={() => inputMasks.cpf()}
                                    onChange={value => handleInfluencer(value, 'cpf')}
                                />
                            </Group>
                        </ColumnFlex>
                    </LineNoWrap>

                    <ButtonSubmit
                        type="submit" 
                        //disabled={!user.firstname || !user.lastname}
                    >
                        Salvar
                    </ButtonSubmit>
                </Form>
            )}
        </ModalFullscreen>
    )
};

export default InfluencerPage;