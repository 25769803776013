import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, hasRoles } from '../services/auth';

const PrivateRoute = ({ children }) => {
    const isLogged = isAuthenticated() && hasRoles('ROLE_ADMIN');

    if (!isLogged) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default PrivateRoute;