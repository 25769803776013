import styled, { css } from 'styled-components';

export const Container = styled.div`
    padding: 2px;
    display: flex;
    border-radius: 9px;
    width: fit-content;
    background-color: #fff;
    min-width: 150px;
`;

const isSelected = css`
    cursor: default;
    color: rgba(255, 255, 255, 1);
    background-color: ${({ color }) => color ? color : 'var(--primary-color)' };
`;

const isDisabled = css`
    cursor: default;
    opacity: .7;
`;

export const Option = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    border-radius: 9px;
    font-size: 16px;
    font-family: 'Cerebri Sans';
    cursor: pointer;
    color: ${({ color }) => color ? 'rgba(30, 23, 45, .5)' : 'rgba(83, 26, 197, .5)'};
    flex: 1;
    background-color: transparent;

    ${({ selected }) => selected && isSelected };
    ${({ disabled }) => disabled && isDisabled };
`;