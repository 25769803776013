import React, { useMemo } from 'react';
import ReactPagination from 'react-paginate';
import { NUM_ITEMS_PAGE } from '../../constants/config';
import { Container } from './style';

const Pagination = ({
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    pageRangeDisplayed,
    onChange,
}) => {
    const itemsPerPage = useMemo(() => {
        return NUM_ITEMS_PAGE;
    }, []);

    const pageCount = useMemo(() => {
        const numItems = itemsCountPerPage || itemsPerPage;

        return Math.ceil(totalItemsCount / numItems);
    }, [itemsPerPage, itemsCountPerPage, totalItemsCount]);

    return (
        <Container className='paginator-container'>
            {totalItemsCount > 0 && (
                <ReactPagination
                    breakLabel="..."
                    nextLabel=">"
                    forcePage={activePage}
                    previousLabel="<"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={pageRangeDisplayed}
                    onPageChange={page => onChange(page.selected)}
                    containerClassName="pagination"
                    pageClassName="pages pagination"
                    activeClassName="itemPaginationActive"
                    disabledClassName="itemDisabled"
                />
            )}
        </Container>
    )
};

export default Pagination;