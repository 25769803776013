import styled from 'styled-components';

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: 30px;

    .uploadcare--widget__button_type_open, .uploaded-file {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        white-space: break-spaces;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
`;

export const Image = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
`;


export const Icon = styled.button`
    border: none;
    outline: none;
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 22px;
    height: 22px;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: rgba(83, 26, 197, .7);
    }
`;

export const Relative = styled.div`
    position: relative;
    width: fit-content;
`;

export const ButtonRemoveImg = styled.button`
    outline: none;
    border: 2px solid var(--secondary-color);
    background-color: var(--primary-color);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
`;

export const Line = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    align-self: flex-start;
    justify-content: space-between;
`;

export const ColumnMFA = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 30px;
    align-items: flex-end;
`;

export const ContentQrCode = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const BoxQrCode = styled.div`
    background-color: var(--primary-color);
    width: 250px;
    height: 250px;
    border-radius: 10px;
    padding: 30px;
`;

export const QrCodeImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const Text = styled.span`
    font-size: 14px;
    margin-top: 40px;
`;