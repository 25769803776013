import React from 'react';
import { AngleLeftIcon } from 'react-line-awesome';
import { 
    Container,
    Header,
    Panel,
    Group,
    Title,
    ButtonGoBack,
} from './style';

const ModalFullscreen = ({ goBackHref, title, headerOptions, children }) => {
    return (
        <Container>
            <Header>
                <Group>
                    <ButtonGoBack to={goBackHref}>
                        <AngleLeftIcon />
                    </ButtonGoBack>
                    <Title>
                        {title}
                    </Title>
                </Group>

                {headerOptions && (
                    <Group>
                        {headerOptions}
                    </Group>
                )}
            </Header>

            <Panel>
                {children}
            </Panel>
        </Container>
    )
};

export default ModalFullscreen;