import React from "react";
import { SortableList } from "./components/List";

export function Sortable({ items, onSortEnd, handleDeleteFile }) {
  return <SortableList 
    items={items} 
    onSortEnd={onSortEnd}
    handleDeleteFile={handleDeleteFile}
    axis="xy" 
  />;
}
