import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 1;
    color: #fff;
    padding-right: 20px;

    &.paginator-container {
        a {
            outline: none;
        }

        .text-action {
            cursor: pointer;
            margin: 0px 10px;
            color: red;
            font-size: 14px;
            display: flex;
            align-items: center;
        }
        .pagination {
            display: flex;
            margin-bottom: 0px !important;

            li, .itemPaginationActive {
                padding: 0px;
                height: 26px;
                width: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                margin-left: 7px;
                margin-right: 7px;
                cursor: pointer;
                display: flex;
                align-items: center;
                border-radius: 50%;
                color: #fff;
                border: 1px solid #fff;
            }
            .previous, .next {
                background-color: transparent;
                color: #fff;
                opacity: 0.6;

                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
            .itemPaginationActive {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
                
                a {
                    color: white;
                    font-weight: 600;
                    outline: none;
                }
            }

            .previous.itemDisabled, .next.itemDisabled {
                /* border-color: var(--disabled-color);
                color: var(--disabled-color);
                cursor: default; */
                display: none;
            }
        }
    }
`;