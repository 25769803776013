import styled, { css } from 'styled-components';

const errorStyle = css`
    border: 1px solid var(--error-color);
`;

const lightStyle = css`
    background-color: #fff;
    color: var(--black-color);

    input {
        color: var(--black-color);

        ::placeholder {
            color: rgba(1, 0, 1, .5);
        }

        :-ms-input-placeholder {
            color: rgba(1, 0, 1, .5);
        }

        ::-ms-input-placeholder {
            color: rgba(1, 0, 1, .5);
        }
    }
`;

const darkStyle = css`
    background-color: var(--black-color);
`;

export const Container = styled.label`
    background-color: var(--secondary-color);
    border-radius: 6px;
    display: flex;
    padding: 6px 15px;
    height: 45px;
    width: 300px;
    gap: 5px;
    position: relative;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-family: 'Cerebri Sans';

    ${({ isSmall }) => isSmall && 'flex: initial !important;'};
    ${({ showErrorMessage }) => showErrorMessage && errorStyle};
    ${({ variant }) => {
        if(variant) {
            if(variant === 'light') return lightStyle;
            if(variant === 'dark') return darkStyle;
        }
    }};
`;

export const Select = styled.select`
    border: none;
    outline: none;
    font-size: 16px;
    color: #fff;
    flex: 1;
    background-color: transparent;

    ::placeholder {
        font-size: 16px;
        font-family: 'Cerebri Sans';
        color: rgba(255, 255, 255, .5);
    }

    :-ms-input-placeholder {
        font-size: 16px;
        font-family: 'Cerebri Sans';
        color: rgba(255, 255, 255, .5);
    }

    ::-ms-input-placeholder {
        font-size: 16px;
        font-family: 'Cerebri Sans';
        color: rgba(255, 255, 255, .5);
    }
`;

export const ContainerIcon = styled.div`
    display: flex;
    align-items: center;
`;

export const ErrorMessage = styled.div`
    position: absolute;
    left: 5px;
    top: 95%;
    padding: 8px 16px;
    z-index: 9999;
    border-radius: 10px;
    color: #FFFFFF;
    background-color: var(--error-color);
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Quicksand';
    width: max-content;
    max-width: 160px;
    border: 0.5px solid var(--error-color);

    &:before {
        content: '';
        display: block;  
        position: absolute;
        top: -16px;
        left: 20px;
        z-index: 9999;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-bottom-color: var(--error-color);
    }

    &:after {
        content: "";
        position: absolute;
        top: -14px;
        left: 28px;
        margin-left: -7px;
        border-width: 7px;
        border-style: solid;
        z-index: 9999;
        border-color: transparent transparent var(--error-color) transparent;
    }
`;