import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NUM_ITEMS_PAGE } from '../../constants/config';
import Select from '../../components/select';
import PageTitle from '../../components/page-title';
import TagStatus from '../../components/tag-status';
import SearchInput from '../../components/search-input';
import alertNotify from '../../components/alert-notify';
import BounceSpinner from '../../components/bounce-spinner';
import NotFoundItems from '../../components/not-found-items';
import Pagination from '../../components/pagination';
import { Table, Tbody, Td, Tr, Image } from '../../components/global-style';
import { getAllBrands, searchBrandsByName } from '../../services/brands';
import userImg from '../../assets/images/person.svg';
import { Box, Line, FlexEnd, LineStart } from './style';

const BrandsPage = ({ navigation }) => {
    const [activeStatus, setActiveStatus] = useState('todas');
    const [activePage, setActivePage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [brands, setBrands] = useState([]);
    const [allBrands, setAllBrands] = useState([]);
    const [displayedBrands, setDisplayedBrands] = useState([]);
    const [totalBrands, setTotalBrands] = useState(0);
    const [isSearching, setIsSearching] = useState(false);

    const navigate = useNavigate();

    const limit = useMemo(() => NUM_ITEMS_PAGE, []);

    const colorsStatus = useMemo(() => {
        return {
            aguardando: 'var(--waiting-status-color)',
            ativo: 'var(--active-status-color)',
            inativo: 'var(--inactive-status-color)',
        }
    }, []);

    const getStatus = useCallback((enabled, welcome) => {
        if(!welcome) {
            return 'aguardando';
        }
        
        return enabled ? 'ativo' : 'inativo';
    }, []);

    const searchBrands = useCallback(async name => {
        setIsLoading(true);

        await searchBrandsByName(name)
        .then(({ data }) => {
            const searchedBrands = data.map(brand => {
                return {
                    user: {
                        id: brand.id,
                        avatarInfo: brand.avatarInfo,
                        company: brand.company,
                        email: brand.email,
                        phone: brand.phone,
                        status: getStatus(brand.enabled, brand.welcome)
                    }
                }
            });

            setIsSearching(true);
            setBrands(searchedBrands);
        })
        .catch(err => {
            alertNotify('Ocorreu um erro ao realizar a pesquisa.', null, 'danger');
        })
        .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        async function getBrands(activePage) {
            setIsLoading(true);

            await getAllBrands(activePage * limit, limit)
            .then(({ data }) => {
                const { count, brands: response } = data;

                setTotalBrands(count);
                setAllBrands(response.map(brand => {
                    return {
                        ...brand,
                        user: {
                            ...brand.user,
                            status: getStatus(brand.user.enabled, brand.user.welcome)
                        }
                    }
                }));
            })
            .catch(error => {
                alertNotify('Ocorreu um erro ao recuperar a listagem de marcas.', null, 'danger');
            })
            .finally(() => setIsLoading(false));
        }

        getBrands(activePage);
    }, [activePage, limit]);

    useEffect(() => setBrands(allBrands), [allBrands]);

    useEffect(() => {
        setDisplayedBrands(activeStatus === 'todas' 
            ? brands 
            : brands.filter(brand => brand.user.status === activeStatus)
        )
    }, [brands, activeStatus]);

    return (
        <Box>
            <Line>
                <PageTitle>
                    Marcas
                </PageTitle>
                <FlexEnd>
                    <Select
                        id="select-status"
                        options={[
                            { value: 'todas', label: 'Todas' },
                            { value: 'ativo', label: 'Ativas' },
                            { value: 'inativo', label: 'Inativas' },
                            { value: 'aguardando', label: 'Aguardando' },
                        ]} 
                        onChange={e => setActiveStatus(e.target.value)}
                        placeholder="Status"
                        selectedOption={activeStatus}
                    />
                    <SearchInput
                        id="search-brand"
                        placeholder="Procurar por marca..."
                        onChange={event => {
                            if(event.target.value.length === 0) {
                                setBrands(allBrands);
                                setIsSearching(false);
                            }
                        }}
                        searchCallback={value => {
                            if(value.length > 0) searchBrands(value);
                        }}
                    />
                </FlexEnd>
            </Line>
            {isLoading ? (
                <BounceSpinner />
            ) : (
                <>
                    {displayedBrands.length > 0 ? (
                        <>
                            <Table>
                                <Tbody>
                                    {displayedBrands.map(({ user: brand }) => {
                                        return (
                                            <Tr key={brand.id} onClick={() => navigate(`/brand/${brand.id}`)}>
                                                <Td>
                                                    <LineStart>
                                                        <Image src={brand.avatarInfo?.cdnUrl || userImg} alt="Logo da marca" />
                                                        <span>
                                                            {brand.company}
                                                        </span>
                                                    </LineStart>
                                                </Td>
                                                <Td>
                                                    {brand.email}
                                                </Td>
                                                <Td>
                                                    {brand.phone}
                                                </Td>
                                                <Td align="right">
                                                    <TagStatus color={colorsStatus[brand.status]}>
                                                        {brand.status}
                                                    </TagStatus>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>

                            {!isSearching && (
                                <Pagination
                                    activePage={activePage}
                                    totalItemsCount={totalBrands}
                                    onChange={(page) => setActivePage(page)}
                                />
                            )}
                        </>
                    ) : (
                        <NotFoundItems>
                            Nenhuma marca encontrada
                        </NotFoundItems>
                    )}
                </>
            )}
        </Box>
    )
};

export default BrandsPage;