import styled from 'styled-components';
import { ResponsiveContainer as Container } from 'recharts';

export const MyTooltip = styled.div`
    background: #AC4AFC 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 4px 10px;
    font-size: 20px;
    font-family: Quicksand;
    letter-spacing: 0px;
    color: #FFFFFF;
    outline: none;
    border: none;
`;

export const ResponsiveContainer = styled(Container)`
`;