import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const NavMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 80px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: var(--secondary-color);
  transition: 0.5s;
  overflow-x: hidden;
  white-space: nowrap;

  .hidden {
    display: none;
  }
  
  :hover {
    width: 230px;

    .expandable {
      width: 230px;

      & span {
        display: block;
      }
    }

    .hidden {
      display: block;
    }

    #logo_k {
      display: none;
    }
  }
`;

export const NavMenuItems = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
`;

export const NavText = styled.li`
  display: flex;
  height: 35px;
  margin-bottom: 10px;
  border-radius: 7px;

  & i {
    font-size: 20px;
  }

  &.active {
    color: var(--primary-color);
    background-color: white;
  }

  &.active svg {
    fill: red;
    color: purple;
  }
`;

export const LogoContainer = styled.div`
  height: 50px;
  width: max-content;
  margin-bottom: 16px;
  display: flex;
  padding-left: 30px;
`;

export const LogoImg = styled.img`
  width: auto;
  height: 50px;
  margin-right: 16px;
`;

export const Icon = styled.img`
  & * {
    fill: red;
  }
`;

export const NavTextLink = styled(NavLink)`
  height: 100%;
  display: flex;
  color: #f5f5f5;
  font-size: 16px;
  align-items: center;
  text-decoration: none;
  width: 100%;
  padding-left: 30px;

  & svg {
    width: 20px;
    height: 20px;
  }
  
  &.active {
    background-color: var(--primary-color);
  }
  & span {
    display: none;
  }

  :hover {
    color: #f5f5f5;
  }
`;

export const NavTextWithoutLink = styled.a`
  height: 100%;
  display: flex;
  color: #f5f5f5;
  font-size: 16px;
  padding-left: 30px;
  border-radius: 4px;
  align-items: center;
  text-decoration: none;
  width: 100%;

  & svg {
    width: 20px;
    height: 20px;
  }

  &.active {
    background-color: white;
    color: var(--primary-color);
  }
  &.active svg {
    color: var(--primary-color);
  }
  & span {
    display: none;
  }

  :hover {
    color: #f5f5f5;
  }
`;

export const NavTextTitle = styled.span`
  margin-left: 30px;
`;

export const MainContainer = styled.div`
  padding: 30px;
  min-height: 100vh;
  background-color: var(--black-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 80px;
  color: #fff;
`;

export const ProfileImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #FFFFFF;

  & + span {
    margin-left: 18px;
  }
`;