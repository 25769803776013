import React, { useCallback, useState } from "react";
import ButtonChangeStatus from "../button-change-status";
import alertNotify from "../alert-notify";
import { activateCampaign, updateStatusCampaign } from "../../services/campaigns";
import {
    BlocoInformacoes,
    DivMainBloco,
    BlocoFundo,
    BlocoNome,
    FlexEndNome,
    W50,
    Logo,
    InfluencerImg,
    NumeroAplicacoes,
    FonteNome,
    BlockInfluencers,
    FontePromo,
    LineLabels,
    LabelStatus,
    LabelDataInicio,
    ChangeStatus
} from './style';

const CampaignBlock = ({ 
    banner,
    status,
    logoEmpresa,
    nomeCampanha,
    nomeEmpresa,
    dataInicio,
    publishers,
    numAplicacoes,
    campaignId,
    changeStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);

    const getStatusName = useCallback((status) => {
      switch(status) {
        case 'ACTIVE':
        return 'Ativa';
        case 'REVIEW':
        return 'Em Revisão';
        case 'FINISHED':
        return 'Finalizada';
        case 'DRAFT':
        return 'Rascunho';
        case "PAUSED":
        return 'Pausada';

        default:
        return 'Rascunho'
      }
    }, []);

    const activateCampaignCallback = useCallback(async campaignId => {
      setIsLoading(true);

      await activateCampaign(campaignId)
      .then(({ data }) => {
        changeStatus(campaignId, 'ACTIVE');
        alertNotify('O status da campanha foi atualizado com sucesso.', null, 'success');
      })
      .catch(err => {
        alertNotify('Ocorreu um erro ao ativar a campanha.', null, 'danger');
      })
      .finally(() => setIsLoading(false));
    }, []);

    const updateStatusCampaignCallback = useCallback(async (campaignId, status) => {
      setIsLoading(true);

      await updateStatusCampaign(campaignId, status)
      .then(({ data }) => {
        changeStatus(campaignId, status);
        alertNotify('O status da campanha foi atualizado com sucesso.', null, 'success');
      })
      .catch(err => {
        alertNotify('Ocorreu um erro ao pausar a campanha.', null, 'danger');
      })
      .finally(() => setIsLoading(false));
    }, []);

    const changeStatusCampaign = useCallback((campaignId, status) => {
      if(status === 'ACTIVE') return activateCampaignCallback(campaignId);
 
      return updateStatusCampaignCallback(campaignId, status);
    }, []);

    return (
        <DivMainBloco>
          <BlocoFundo bg={banner}>
            <LineLabels>
              <LabelStatus status={status}>
                {getStatusName(status)}
              </LabelStatus>
              
              {status === 'REVIEW' && (
                <LabelDataInicio>
                  {`Data início: ${new Date(dataInicio).toLocaleDateString() || ""}`}
                </LabelDataInicio>
              )}
            </LineLabels>

            <ChangeStatus>
              <ButtonChangeStatus 
                activeStatus={status}
                isLoading={isLoading}
                onClickStatus={status => changeStatusCampaign(campaignId, status)}
              />
            </ChangeStatus>
          </BlocoFundo>
          <BlocoNome>
            <W50>
              <Logo src={logoEmpresa} />
            </W50>
            <BlocoInformacoes>
                <FonteNome>
                  {nomeCampanha}
                </FonteNome>
                <FontePromo>
                  {nomeEmpresa}
                </FontePromo>
                <FontePromo>
                  {new Date(dataInicio).toLocaleDateString() || ""}
                </FontePromo>
            </BlocoInformacoes>
            <FlexEndNome>
              <BlockInfluencers>
                {publishers.map((publisher, index) => {
                  const rest = numAplicacoes - (index + 1);
                  const isLastChild = index + 1  === publishers.length;

                  return (
                    <>
                      <InfluencerImg src={publisher.avatarInfo.cdnUrl} />

                      {isLastChild && rest > 0 && (
                        <NumeroAplicacoes>
                          {`${rest}+`}
                        </NumeroAplicacoes>
                      )}
                    </>
                  )
                })}
              </BlockInfluencers>
            </FlexEndNome>
          </BlocoNome>
        </DivMainBloco>
    )
}

export default CampaignBlock;
