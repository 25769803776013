import React from 'react';
import MaskedInput from 'react-text-mask';
import { Container, ContainerIcon, ErrorMessage } from './style';

const InputDefault = ({ 
    id,
    value,
    type,
    placeholder,
    onChange,
    icon,
    small,
    variant,
    leftText,
    errorMessage,
    mask,
    showErrorMessage,
}) => {
    return (
        <Container 
            isSmall={small}
            className="input"
            showErrorMessage={showErrorMessage} 
            variant={variant}
        >
            {leftText}

            {!!mask ? (
                <MaskedInput
                    id={id}
                    mask={mask}
                    value={value}
                    guide={false}
                    placeholder={placeholder}
                    onChange={({ target }) => onChange(target.value)}
                />
            ) : (
                <input
                    id={id}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={({ target }) => onChange(target.value)}
                />
            )}


            {icon && (
                <ContainerIcon>
                    {icon}
                </ContainerIcon>
            )}

            {showErrorMessage && (
                <ErrorMessage>
                    {errorMessage}
                </ErrorMessage>
            )}
        </Container>
    )
};

export default InputDefault;