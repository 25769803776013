import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '../../constants/api';
import Input from "../../components/input";
import Button from "../../components/button-login";
import logoKuak from "../../assets/images/kuak_logo_branco.svg";
import { setTokenOnHeader } from '../../services';
import { login, getUserProperties, setUserProperties } from '../../services/auth';
import { setItem } from '../../services/localStorage';
import { Container, Form, Title, Logo, Icon } from './style';

const LoginPage = ({ navigation }) => {
    const [credentials, setCredentials] = useState({ email: null, password: null });
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleChange = useCallback((value, field) => {
        setError(false);
        setCredentials(prev => {
            return {
                ...prev,
                [field]: value,
            }
        });
    }, []);

    const submit = useCallback((e, credentials) => {
        e.preventDefault();
        setIsLoading(true);

        login(credentials)
        .then(async ({ data }) => {
            if (data.token && data.role.some(role => role.authority === 'ROLE_ADMIN')) {
                setItem("userProperties", null);
                setItem(TOKEN_KEY, data.token);
                setItem(REFRESH_TOKEN_KEY, data.refresh_token);
                setTokenOnHeader(data.token);
                
                const user = await getUserProperties();

                if(user.enable_2fa === '1') {
                    navigate('/two-auth');
                }
                else {
                    user.isAuthenticated = true;

                    setUserProperties(user);
                    navigate('/dashboard');
                }
            }
            else {
                setError(true);
            }
        })
        .catch(error => {
            setError(true);
        })
        .finally(() => setIsLoading(false));

    }, []);
    
    return (
        <Container>
            <Logo src={logoKuak} alt="Logo Kuak" />

            <Title>
                Bem Vindo!
            </Title>

            <Form onSubmit={(e) => submit(e, credentials)}>
                <Input 
                    id="email" 
                    type="email"
                    placeholder="E-mail"
                    value={credentials.email}
                    onChange={value => handleChange(value, 'email')}
                />

                <Input 
                    id="password" 
                    type="password"
                    placeholder="Senha"
                    value={credentials.password}
                    errorMessage="Oops, senha incorreta. Tente novamente."
                    showErrorMessage={error}
                    onChange={value => handleChange(value, 'password')}
                    icon={<Icon type="button" onClick={() => navigate('/recover-password')}>?</Icon>}
                />

                <Button 
                    type="submit" 
                    isLoading={isLoading}
                    disabled={!credentials.email || !credentials.password}
                >
                    Entrar
                </Button>
            </Form>
        </Container>
    )
};

export default LoginPage;