import React from 'react';
import { Container } from './style';

const BounceSpinner = () => {
    return (
        <Container>
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </Container>
    )
};

export default BounceSpinner;