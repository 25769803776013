import { LOCAL_STORAGE_PREFIX } from "./config";

const API_URLs = {
    prod: "https://nestjs.kuak.com",
    staging: "https://kuak-api.herokuapp.com",
    local: "http://localhost:8080",
};
  
export const API_URL = API_URLs.prod;

export const TOKEN_KEY = `${LOCAL_STORAGE_PREFIX}:token`;
export const REFRESH_TOKEN_KEY = `${LOCAL_STORAGE_PREFIX}:refresh_token`;
