import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: ${({ isOpen }) => isOpen ? '4px 4px 10px 10px' : '4px'};
    font-size: 14px;
    background-color: #2B2B2B;
    gap: 8px;
    border-radius: 4px;
`;

export const Line = styled.div`
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
`;

const colors = {
    "ACTIVE": '#00B9FF',
    "REVIEW": '#925FFA',
    "FINISHED": '#00C268',
    "DRAFT": '#D51E9E',
    "PAUSED": '#FDC760',
}
  
export const LabelStatus = styled.div`
    box-shadow: 10px 10px 10px -8px rgb(0 0 0 / 75%);
    font-size: 0.66rem;
    border-radius: 7px;
    padding: 0.17rem 0.8rem;
    color: #fff;
    font-weight: 700;
    background-color: ${({ status }) => colors[status]};
    z-index: 0;
`;

export const Button = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    width: fit-content;
    height: fit-content;
    color: #fff;
    padding: 0;
    display: flex;
    align-items: center;

    i {
        font-size: 18px;
    }
`;