import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { CogIcon } from 'react-line-awesome';
import { Container, Line, LabelStatus, Button } from './style';

const ButtonChangeStatus = ({ onClickStatus, activeStatus, isLoading }) => {
    const [isOpen, setIsOpen] = useState(false);

    const listStatus = useMemo(() => [
        { key: 'ACTIVE', label: 'Ativa' },
        { key: 'REVIEW', label: 'Em Revisão' },
        { key: 'FINISHED', label: 'Finalizada' },
        { key: 'DRAFT', label: 'Rascunho' },
        { key: 'PAUSED', label: 'Pausada' },
    ], []);

    const containerRef = useRef(null);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
          // If the menu is open and the clicked target is not within the menu,
          // then close the menu
          if (isOpen && containerRef.current && !containerRef.current.contains(e.target)) {
            setIsOpen(false)
          }
        }
    
        document.addEventListener("mousedown", checkIfClickedOutside)
    
        return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isOpen, containerRef]);

    return (
        <Container isOpen={isOpen} ref={containerRef}>
            {isOpen ? (
                <>
                    <Line>
                        <span>
                            Status da Campanha
                        </span>

                        <Button onClick={() => setIsOpen(!isOpen)}>
                            <CogIcon />
                        </Button>
                    </Line>
                    
                    {isLoading ? (
                        <Spinner 
                            as="span" 
                            animation="border" 
                            role="status" 
                            size="sm" 
                            variant="light"
                        />
                    ) : (
                        <>
                            {listStatus.map(status => {
                                if(status.key !== activeStatus) {
                                    return (
                                        <Button
                                            key={status.key}
                                            onClick={() => onClickStatus(status.key)}
                                        >
                                            <LabelStatus status={status.key}>
                                                {status.label}
                                            </LabelStatus>
                                        </Button>
                                    )
                                }

                                return;
                            })}
                        </>
                    )}

                </>
            ) : (
                <Button onClick={() => setIsOpen(true)}>
                    <CogIcon />
                </Button>
            )}
        </Container>
    )
};

export default ButtonChangeStatus;