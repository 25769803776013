import api from './index';

export const getAllBrands = (offset, limit) => {
  return api.get(`/users/dashboard/allbrands?offset=${offset}&limit=${limit}`);
}

export const searchBrandsByName = (text) => {
  return api.get(`/users/byname/BRAND/${text}`);
}

export const getBrandById = (brandId) => {
  return api.get(`/users/${brandId}`);
}

export const updateBrand = (brand) => {
  const { id, ...data } = brand;

  return api.post(`/users/changeoptions/${id}`, data);
}

export const deleteBrand = (brandId) => {
  return api.delete(`/users/BRAND/${brandId}`);
}